import React from "react";
import styled from "styled-components";
import ButtonWithText from "./Button";

const ToggleWrapper = styled.div`
    display: flex;
`;

const Toggle = ({ selected, setSelected, options, size = "medium", ...props }) => {
    return (
        <ToggleWrapper>
            {options.map((option, index) => (
                <ButtonWithText
                    tooltip={option.tooltip}
                    style={{
                        ...(index === 0
                            ? { borderTopRightRadius: "0", borderBottomRightRadius: "0" }
                            : index === options.length - 1
                            ? { borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }
                            : { borderRadius: "0" }),
                        ...(index === 0 && selected !== option.value && { borderRight: "none" }),
                        ...(index === options.length - 1 && selected !== option.value && { borderLeft: "none" }),
                    }}
                    icon={option.icon}
                    key={index}
                    //variant={selected === option.value ? "checkboxActive" : "checkboxInActive"}
                    variant={selected === option.value ? "primary" : "passive"}
                    size={size}
                    buttonStyle={selected === option.value ? "selectedOption" : "option"}
                    onClick={() => setSelected(option.value)}
                    {...props}
                >
                    {option.value}
                </ButtonWithText>
            ))}
        </ToggleWrapper>
    );
};

export default Toggle;
