import React from "react";
import styled from "styled-components";
import ExtensionDealSideBar from "./ExtensionDealSideBar";
import ExtensionNewDeal from "./ExtensionNewDeal";
import ExtensionNewDealFooter from "./ExtensionNewDealFooter";
import { colors } from "app/popleads/components/ui-kit/colors";
import { useExtensionContext } from "../ExtensionContext";
import Label from "app/popleads/components/ui-kit/Label";
import { nameTag } from "app/popleads/app/functions/util";

export const NewDealContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const NewDealMenu = styled.div`
    min-width: 225px;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.colors?.neutral?.[100] || "#E5E7EB"};
`;

export const NewDealContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const NewDealContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
`;

const CompanyInfoContainer = styled.div`
    height: 131px;
    background-color: ${colors.white};
    border-top: 1px solid ${colors.neutral[100]};
    padding: 20px;
`;

const CompanyHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CompanyIcon = styled.div`
    height: 40px;
    width: 40px;
    background-color: ${colors.neutral[100]};
    color: ${colors.neutral[700]};
    border: 1px solid ${colors.neutral[300]};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
`;

const CompanyName = styled.div`
    margin-top: 12px;
    font-size: 12px;
    font-weight: 500;
`;

const SidebarWrapper = styled.div`
    height: calc(100% - 131px);
`;

const ExtensionNewDealPage = ({ isActive, setIsActive }) => {
    const { profile } = useExtensionContext();
    return (
        <NewDealContainer>
            <NewDealMenu>
                <SidebarWrapper>
                    <ExtensionDealSideBar isActive={isActive} setIsActive={setIsActive} />
                </SidebarWrapper>
                <CompanyInfoContainer>
                    <CompanyHeader>
                        <CompanyIcon>{profile?.accountName ? nameTag(profile?.accountName) : "-"}</CompanyIcon>
                        <Label variant="success">13 Deals</Label>{" "}
                        {/* TODO: profilin accountunun toplam deal sayısı gelecek*/}
                    </CompanyHeader>
                    <CompanyName>{profile?.accountName}</CompanyName>
                </CompanyInfoContainer>
            </NewDealMenu>
            <NewDealContentWrapper>
                <NewDealContent>
                    <ExtensionNewDeal />
                </NewDealContent>
                <ExtensionNewDealFooter />
            </NewDealContentWrapper>
        </NewDealContainer>
    );
};

export default ExtensionNewDealPage;
