import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "app/popleads/components/ui-kit/colors";
import useGetRecentNotes from "app/popleads/hooks/useGetRecentNotes";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import moment from "moment";
import { StyledCardContent, StyledCardFooter } from "./common/styled";
import { ExtensionBody, ExtensionSmall } from "app/popleads/components/ui-kit/Text";
import useGetRecentPersonalActivity from "app/popleads/hooks/useGetRecentPersonalActivity";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import useGetCompanyUsers from "app/popleads/hooks/useGetCompanyUsers";
import { StyledCheckbox } from "app/popleads/components/ui-kit/Select";

const FeedContainer = styled.div`
    padding: 0px 12px;
`;

const FeedTabs = styled.div`
    display: flex;
    gap: 20px;
    margin: 16px 0;
    border-bottom: 1px solid ${colors.neutral[200]};
`;

const FeedTabsItem = styled.span`
    color: ${({ isActive }) => (isActive ? colors.primary[900] : colors.neutral[700])};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    padding: 0 8px;
    height: 28px;
    margin-bottom: -1px;
    ${({ isActive }) => isActive && `border-bottom: 1px solid ${colors.primary[900]};`}
`;

const StyledContentWrapper = styled.div`
    overflow-y: auto;
    height: calc(100% - 90px);
    padding-bottom: 16px;
`;

const StyledCardItemContent = styled.div`
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledCardText = styled(ExtensionBody)`
    color: ${({ variant }) => (variant ? colors[variant][800] : colors.neutral[800])};
    display: flex;
    font-weight: 400;
    overflow-wrap: anywhere;
`;

const Avatar = styled.img`
    border-radius: 50%;
    width: 24px;
    height: 24px;
`;

const AvatarSvg = styled(SVG)`
    border-radius: 50%;
    min-width: 24px;
    min-height: 24px;
`;

const StayledLink = styled.a`
    font-size: 16px;
    color: ${colors.primary[900]};
`;

const ClippedText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #384d567a;
    z-index: 999;
`;

const StyledModalContainer = styled.div`
    position: absolute;
    top: 0;
    z-index: 1000;
    padding: 0 8px;
    width: 100%;
`;

const StyledModal = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    padding: 12px 0;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    padding: 0 16px;

    /* General scrollbar style */
    &::-webkit-scrollbar {
        width: 9px; /* Width of the scrollbar */
    }

    /* Scrollbar thumb style */
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.neutral[500]}; /* Color of the scrollbar thumb */
        border-radius: 9px; /* Rounded corners */
        border: 3px solid transparent;
        background-clip: content-box;
        min-height: 58px;
    }

    /* Thumb color on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: ${colors.neutral[700]};
    }

    /* Scrollbar corner style (if any) */
    &::-webkit-scrollbar-corner {
        background-color: ${colors.neutral[100]};
    }
`;

const ModalItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SelectAll = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 25px 0 16px;
    margin-bottom: 18px;

    @supports (-moz-appearance: none) {
        padding: 0 16px 0 16px;
    }
`;

const ModalText = styled(ExtensionBody)`
    color: ${colors.neutral[800]};
`;

const ExtensionFeedList = () => {
    const [selectedTab, setSelectedTab] = useState("Recent Notes");
    const [recentNoteList, setRecentNoteList] = useState([]);
    const [recentActivityList, setRecentActivityList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { recentNotes } = useGetRecentNotes({ users: selectedUsers });
    const { recentActivity } = useGetRecentPersonalActivity();
    const { companyUsers } = useGetCompanyUsers({ type: "byRoles" });
    const options = [{ value: "Recent Notes" }, { value: "My Recent Actions" }];

    useEffect(() => {
        const noteList = recentNotes.reverse()?.map((note) => {
            const variant =
                note.tip === "otomatik" && note.metin === "New Lead"
                    ? "success"
                    : note.tip === "otomatik"
                    ? "warning"
                    : null;
            const hasIcon = note.metin === "New Lead" || note.metin === "New Contact";
            return {
                id: note.id,
                icon: note.tip === "otomatik" && (
                    <StyledCardContent
                        variant={"warning"}
                        className="material-symbols-outlined"
                        style={{ fontSize: "22px", fontWeight: "300" }}
                    >
                        sync_problem
                    </StyledCardContent>
                ),
                header: (
                    <StyledCardText variant={variant}>
                        {hasIcon && (
                            <span style={{ width: "24px", textAlign: "center", marginRight: "8px" }}>
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Extension/pop-it.svg")}
                                    style={{ width: "16px", height: "13px" }}
                                />
                            </span>
                        )}
                        {note.metin}
                    </StyledCardText>
                ),
                content: (
                    <div>
                        <StyledCardItemContent>
                            {note.profil?.resim ? (
                                <Avatar
                                    src={note?.profil?.resim}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg");
                                    }}
                                />
                            ) : (
                                <AvatarSvg src={toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg")} />
                            )}
                            <ClippedText>{`${note?.profil?.ad} ${note?.profil?.soyad}`}</ClippedText>
                            <StayledLink
                                href={`/extension?profileId=${note?.profil?.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="material-symbols-outlined"
                            >
                                open_in_new
                            </StayledLink>
                        </StyledCardItemContent>
                        <ExtensionSmall>{note?.profil?.baslik}</ExtensionSmall>
                    </div>
                ),
                footer: (
                    <StyledCardFooter>
                        {moment(note.zaman).format("MMM DD, YYYY HH:mm")} | Created by {note.kayitci}
                    </StyledCardFooter>
                ),
            };
        });

        setRecentNoteList(noteList);
    }, [recentNotes]);

    useEffect(() => {
        const activityList = recentActivity?.map((activity) => {
            const variant = activity.title.includes("Lead") ? "success" : "warning";
            const hasIcon = activity.title === "New Lead" || activity.title === "New Contact";
            return {
                icon: (
                    <StyledCardContent
                        variant={"warning"}
                        className="material-symbols-outlined"
                        style={{ fontSize: "22px", fontWeight: "300" }}
                    >
                        sync_problem
                    </StyledCardContent>
                ),
                header: (
                    <StyledCardContent variant={variant}>
                        {hasIcon && (
                            <span style={{ width: "24px", textAlign: "center", marginRight: "8px", color: "inherit" }}>
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Extension/pop-it.svg")}
                                    style={{ width: "16px", height: "13px" }}
                                />
                            </span>
                        )}
                        {activity.title}
                    </StyledCardContent>
                ),
                content: (
                    <div>
                        <StyledCardItemContent>
                            {activity?.profil[0]?.resim ? (
                                <Avatar
                                    src={activity?.profil[0]?.resim}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg");
                                    }}
                                />
                            ) : (
                                <AvatarSvg src={toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg")} />
                            )}
                            <ClippedText>{`${activity?.profil[0]?.ad} ${activity?.profil[0]?.soyad}`}</ClippedText>
                            <StayledLink
                                href={`/extension?profileId=${activity?.profil[0]?.id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="material-symbols-outlined"
                            >
                                open_in_new
                            </StayledLink>
                        </StyledCardItemContent>
                        <ExtensionSmall>{activity?.profil[0]?.baslik}</ExtensionSmall>
                    </div>
                ),
                footer: <StyledCardFooter>{moment(activity.time).format("MMM DD, YYYY HH:mm")}</StyledCardFooter>,
            };
        });

        setRecentActivityList(activityList);
    }, [recentActivity]);

    return (
        <>
            {isOpen && (
                <>
                    <Overlay onClick={() => setIsOpen(false)} />
                    <StyledModalContainer>
                        <StyledModal>
                            <SelectAll>
                                <ModalText>Select All</ModalText>
                                <StyledCheckbox
                                    defaultChecked={selectedUsers.length === companyUsers.length}
                                    onClick={(e) => {
                                        if (e.target.checked) {
                                            const allUsers = companyUsers.map((user) => user.id);
                                            setSelectedUsers(allUsers);
                                        } else {
                                            setSelectedUsers([]);
                                        }
                                    }}
                                />
                            </SelectAll>
                            <ModalContent>
                                {companyUsers?.map((user) => (
                                    <ModalItem key={user.id}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {user.linkedinPicture ? (
                                                <Avatar
                                                    src={user.linkedinPicture}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = toAbsoluteUrl(
                                                            "/media/svg/icons/Extension/user-avatar.svg"
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <AvatarSvg
                                                    src={toAbsoluteUrl("/media/svg/icons/Extension/user-avatar.svg")}
                                                />
                                            )}
                                            <ModalText style={{ marginLeft: "8px" }}>{user.name}</ModalText>
                                        </div>
                                        <StyledCheckbox
                                            checked={selectedUsers.includes(user.id)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedUsers([...selectedUsers, user.id]);
                                                } else {
                                                    setSelectedUsers(selectedUsers.filter((id) => id !== user.id));
                                                }
                                            }}
                                        />
                                    </ModalItem>
                                ))}
                            </ModalContent>
                        </StyledModal>
                    </StyledModalContainer>
                </>
            )}
            <FeedContainer>
                <FeedTabs>
                    {options.map((option, index) => (
                        <FeedTabsItem
                            key={index}
                            isActive={selectedTab === option.value}
                            onClick={() => setSelectedTab(option.value)}
                        >
                            {option.value}
                        </FeedTabsItem>
                    ))}
                    {selectedTab === "Recent Notes" && (
                        <FeedTabsItem
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                                setIsOpen(true);
                                // setFilters([53]);
                            }}
                        >
                            <span
                                className={"material-symbols-outlined"}
                                style={{ fontSize: "20px", lineHeight: "18px" }}
                            >
                                person_search
                            </span>
                        </FeedTabsItem>
                    )}
                </FeedTabs>
            </FeedContainer>
            <StyledContentWrapper>
                <FeedContainer>
                    {selectedTab === "Recent Notes" ? (
                        <ExtensionCard haveViewMore={false} cardItems={recentNoteList} />
                    ) : (
                        <ExtensionCard haveViewMore={false} cardItems={recentActivityList} />
                    )}
                </FeedContainer>
            </StyledContentWrapper>
        </>
    );
};

export default ExtensionFeedList;
