import { colors } from "app/popleads/components/ui-kit/colors";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import ProfileCard from "app/popleads/components/ui-kit/ProfileCard";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";
import FeatherIcon from "feather-icons-react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { useExtensionContext } from "../ExtensionContext";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import { StyledFooterButton } from "./ExtensionNewTask";
import Switch from "app/popleads/components/ui-kit/Switch";
const ExtensionPopupContentBody = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
`;

const StyledLocationContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const StyledButtonContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: 18px;
`;
const StyledText = styled("div")`
    font-size: 14px;
    font-weight: 500;
    color: ${colors.neutral[800]};
`;
const workListArray = [
    {
        icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/work-bag.svg")} />,
        content: <StyledText>Popleads Solutions</StyledText>,
    },
    {
        content: (
            <StyledLocationContainer>
                <div>
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                </div>
                <StyledText> Changed to “Imperum”</StyledText>
            </StyledLocationContainer>
        ),
    },
    {
        icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/bag-extension.svg")} />,
        content: <StyledText>Co-Founder & CEO </StyledText>,
    },
    {
        content: (
            <StyledLocationContainer>
                <div>
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                </div>
                <StyledText> Changed to “Co-Founder & CEO at Popleads, Co-Founder & COO at Imperum”</StyledText>
            </StyledLocationContainer>
        ),
    },
];

const locationArray = [
    {
        icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/location.svg")} />,
        content: <StyledText>"North Macedonia",</StyledText>,
    },
    {
        content: (
            <StyledLocationContainer>
                <div>
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/warning2.svg")} />
                </div>
                <StyledText> Changed to “Skopje, North Macedonia”</StyledText>
            </StyledLocationContainer>
        ),
    },
];
const StyledFooter = styled(StyledFooterButton)`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 410px;
`;
// const StyledContainer = styled("div")`
//     display: flex;
//     flex-direction: column;
//     gap: 24px;
// `;
const StyledAboutPageWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
const ExtensionAboutPage = () => {
    const { profile, isProfileLoading, updateProfileLoading } = useExtensionContext();
    const [emails, setEmails] = useState([]);
    const [phones, setPhones] = useState([]);

    const generateContent = (profile, field, hasRightContent = false) => {
        let arrayOfContent = [];
        const regex = new RegExp(`^${field}\\d?$`);

        if (!profile || !field) return arrayOfContent;

        const icon = field === "email" ? "mail" : "phone";

        for (const [key, value] of Object.entries(profile)) {
            if (!key.includes(field) || !regex.test(key) || !value) continue;
            arrayOfContent.push({
                icon: <FeatherIcon strokeWidth="1px" icon={icon} size="16" />,
                content: (
                    <StyledText>
                        <InputWithIcon variant={"unstyled"} value={value} debounceTime={0} onChange={() => {}} />{" "}
                    </StyledText>
                ),
            });
        }
        if (hasRightContent && arrayOfContent.length > 0) {
            arrayOfContent[0].rightContent = (
                <StyledButtonContainer>
                    <ButtonWithText icon="remove" iconType="material" variant="danger" size="circle" type="inverted" />
                    <ButtonWithText icon="add" iconType="material" variant="primary" size="circle" type="default" />
                </StyledButtonContainer>
            );
        }
        return arrayOfContent;
    };

    useEffect(() => {
        if (profile) {
            setEmails(generateContent(profile, "email", true));
            setPhones(generateContent(profile, "tel", true));
        }
    }, [profile]);

    return (
        <>
            <ExtensionPopupContentBody>
                {isProfileLoading || updateProfileLoading ? (
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <StyledAboutPageWrapper>
                        <ProfileCard variant="neutralInActive" />
                        <div>
                            <ExtensionCard cardTitle="Contact" cardItems={emails} />
                            <ExtensionCard cardItems={phones} />
                        </div>
                        <ExtensionCard cardTitle="Location" cardItems={locationArray} />
                        <ExtensionCard haveViewMore={false} cardTitle="Work" cardItems={workListArray} />
                    </StyledAboutPageWrapper>
                )}
            </ExtensionPopupContentBody>
            <StyledFooter>
                <ButtonWithText icon="remove" iconType="material" variant="danger" size="circle" type="inverted">
                    No-Export
                </ButtonWithText>
                <Switch />
            </StyledFooter>
        </>
    );
};

export default ExtensionAboutPage;
