import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./colors";
import { rgba } from "polished";
import FeatherIcon from "feather-icons-react";
import { TooltipOverlay } from "../TooltipOverlay";

export const MaterialIcon = styled("span")`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
`;

const getIconColor = (variant, buttonStyle, isHover = false, iconVariant) => {
    let effectiveVariant = isHover && iconVariant ? iconVariant : variant;
    if (!colors[effectiveVariant]) effectiveVariant = "primary";
    switch (buttonStyle) {
        case "inverted":
        case "option":
            return colors[effectiveVariant][900];
        case "borderless":
            return colors[effectiveVariant][900];
        case "tag":
            return colors[effectiveVariant][700];
        case "selectedOption":
            return effectiveVariant === "neutral" ? colors.neutral[700] : colors[effectiveVariant][900];
        case "default":
        default:
            return colors[effectiveVariant][50];
    }
};

const StyledMaterialIcon = styled(MaterialIcon)`
    margin: ${({ position }) =>
        position === "right" ? "0 0 0 8px" : position === "left" ? "0 8px 0 0" : position === "center" && "0"};
    cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
    color: ${({ variant, buttonStyle }) => getIconColor(variant, buttonStyle)};
    transition: color 0.2s ease;

    &:hover {
        color: ${({ variant, buttonStyle, iconVariant }) => getIconColor(variant, buttonStyle, true, iconVariant)};
    }
`;

const StyledFeatherIcon = styled(FeatherIcon)`
    margin: ${({ position }) =>
        position === "right" ? "0 0 0 8px" : position === "left" ? "0 8px 0 0" : position === "center" && "0"};
    cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
    color: ${({ variant, buttonStyle }) => getIconColor(variant, buttonStyle)};
    transition: color 0.2s ease;

    &:hover {
        color: ${({ variant, buttonStyle, iconVariant }) => getIconColor(variant, buttonStyle, true, iconVariant)};
    }
`;

const buttonSizes = {
    xsmall: {
        height: "32px",
        padding: "0 12px",
        fontSize: "12px",
        fontWeight: "500",
        letterSpacing: "-0.17px",
    },
    small: {
        height: "32px",
        padding: "0 16px",
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: "-0.17px",
    },
    medium: {
        height: "40px",
        padding: "0 20px",
        fontSize: "0.91563rem",
        fontWeight: "500",
        lineHeight: "1.2938rem",
    },
    circle: {
        height: "24px",
        fontSize: "14px",
        fontWeight: "500",
        borderRadius: "50%",
    },
    option: {
        height: "32px",
        padding: "0 8px",
        fontSize: "12px",
        fontWeight: "500",
        letterSpacing: "0",
    },
    toggle: {
        height: "32px",
        padding: "0 24px",
        fontSize: "12px",
        fontWeight: "500",
        letterSpacing: "0",
        borderRadius: "6px",
    },
};

const baseButtonStyles = (variant) => css`
    height: ${(props) => buttonSizes[props.size]?.height || buttonSizes.medium.height};
    display: flex;
    justify-content: center;
    border-radius: ${(props) => buttonSizes[props.size]?.borderRadius ?? "8px"};
    width: ${(props) => (props.icon ? buttonSizes[props.size].height : "auto")};
    padding: ${(props) => (props.icon ? "0" : buttonSizes[props.size].padding || buttonSizes.medium.padding)};
    font-size: ${(props) => buttonSizes[props.size]?.fontSize || buttonSizes.medium.fontSize} !important;
    font-weight: ${(props) => buttonSizes[props.size]?.fontWeight || buttonSizes.medium.fontWeight};
    letter-spacing: ${(props) => buttonSizes[props.size]?.letterSpacing || "auto"};
    line-height: ${(props) => buttonSizes[props.size]?.lineHeight || "auto"};
`;

const defaultButtonStyles = (variant) => css`
  ${baseButtonStyles}
  border: 1.5px solid ${colors[variant][900]};
  background-color: ${colors[variant][900]};
  color: ${colors[variant][50]};

  &:hover {
    background-color: ${colors[variant][950]};
    box-shadow: 0 4px 8px ${rgba(colors[variant][950], 0.2)};
  }

  &:active {
    background-color: ${colors[variant][900]};
    color: ${colors[variant][200]};
    box-shadow: inset 0 2px 4px ${rgba(colors[variant][950], 0.2)};
  }
`;

const invertedButtonStyles = (variant) => css`
  ${baseButtonStyles}
  border: 1.5px solid ${colors[variant][900]};
  
  background-color: ${colors.white};
  color: ${colors[variant][900]};

  &:hover {
    border: 1.5px solid ${colors[variant][950]};
    box-shadow: 0 4px 8px ${rgba(colors[variant][950], 0.2)};
  }

  &:active {
    box-shadow: inset 0 2px 4px ${rgba(colors[variant][950], 0.2)};
    color: ${colors[variant][800]};
  }
`;

const borderlessButtonStyles = (variant) => css`
    ${baseButtonStyles}
    border: none;
    background-color: transparent;
    color: ${colors[variant][900]};

    &:hover {
        text-decoration: ${(props) => (props.icon ? "none" : "underline")};
        color: ${colors[variant][950]};
    }
`;

const optionButtonStyles = (variant) => css`
    ${baseButtonStyles}
    border: 1.5px solid ${colors[variant][900]};
    background-color: transparent;
    color: ${colors[variant][900]};

    &:hover {
        border: 1.5px solid ${colors[variant][950]};
        box-shadow: 0 4px 8px ${rgba(colors[variant][950], 0.2)};
    }

    &:active {
        box-shadow: inset 0 2px 4px ${rgba(colors[variant][900], 0.2)};
        color: ${colors[variant][800]};
    }

`;

const selectedOptionButtonStyles = (variant) => css`
    ${baseButtonStyles}
    border: 1.5px solid ${variant === "neutral" ? colors.neutral[700] : colors[variant][900]};
    background-color: ${variant === "neutral" ? colors.neutral[100] : colors[variant][50]};
    color: ${variant === "neutral" ? colors.neutral[700] : colors[variant][900]};

    &:hover {
        border: 1.5px solid ${colors[variant][950]};
        box-shadow: 0 4px 8px ${rgba(colors[variant][950], 0.2)};
    }

    &:active {
        box-shadow: inset 0 2px 4px ${rgba(colors[variant][900], 0.2)};
        color: ${colors[variant][800]};
    }
`;

const tagButtonStyles = (variant) => css`
    ${baseButtonStyles}
    border: 1px solid ${colors[variant][400]};
    background-color: ${colors[variant][25]};
    color: ${colors[variant][700]};
`;

const variantStyles = (variant = "primary", type) => {
    switch (type) {
        case "default":
            return defaultButtonStyles(variant);
        case "inverted":
            return invertedButtonStyles(variant);
        case "borderless":
            return borderlessButtonStyles(variant);
        case "option":
            return optionButtonStyles(variant);
        case "selectedOption":
            return selectedOptionButtonStyles(variant);
        case "tag":
            return tagButtonStyles(variant);
        default:
            return undefined;
    }
};

const Buttonpl = styled.button`
    border: 0;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
        cursor: default;
    }

    &:disabled {
        cursor: not-allowed;
    }

    ${({ variant, buttonStyle }) => variantStyles(variant, buttonStyle)}
`;

const ButtonText = styled.span`
    white-space: nowrap;
`;

const ButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 8px;
`;

const IconComponent = ({
    iconType,
    icon,
    position,
    testId,
    size = "medium",
    onClick,
    variant,
    iconVariant,
    buttonStyle,
}) => {
    if (iconType === "material") {
        return (
            <StyledMaterialIcon
                data-testid={`material-${testId}`}
                className="material-symbols-outlined"
                position={position}
                onClick={onClick}
                variant={variant}
                iconVariant={iconVariant}
                buttonStyle={buttonStyle}
            >
                {icon}
            </StyledMaterialIcon>
        );
    }

    return (
        <StyledFeatherIcon
            data-testid={`feather-${testId}`}
            icon={icon}
            size="16"
            position={position}
            onClick={onClick}
            variant={variant}
            iconVariant={iconVariant}
            buttonStyle={buttonStyle}
        />
    );
};

const ButtonContent = ({
    iconType,
    leftIcon,
    icon,
    rightIcon,
    children,
    size,
    rightIconClick,
    leftIconClick,
    variant,
    buttonStyle,
    leftIconVariant,
    rightIconVariant,
    iconVariant,
}) => (
    <ButtonText>
        {leftIcon && (
            <IconComponent
                onClick={leftIconClick}
                iconType={iconType}
                icon={leftIcon}
                position="left"
                testId={leftIcon}
                size={size}
                variant={variant}
                iconVariant={leftIconVariant || iconVariant}
                buttonStyle={buttonStyle}
            />
        )}
        {!icon && children}
        {icon && (
            <IconComponent
                iconType={iconType}
                icon={icon}
                position="center"
                testId={icon}
                size={size}
                variant={variant}
                iconVariant={iconVariant}
                buttonStyle={buttonStyle}
            />
        )}
        {rightIcon && (
            <IconComponent
                onClick={rightIconClick}
                iconType={iconType}
                icon={rightIcon}
                position="right"
                testId={rightIcon}
                size={size}
                variant={variant}
                iconVariant={rightIconVariant || iconVariant}
                buttonStyle={buttonStyle}
            />
        )}
    </ButtonText>
);

const ButtonWithText = ({
    variant,
    children,
    leftIcon,
    icon,
    rightIcon,
    tooltip,
    iconType,
    buttonStyle,
    size = "medium",
    rightIconClick,
    leftIconClick,
    onClick,
    iconVariant,
    leftIconVariant,
    rightIconVariant,
    type,
    ...props
}) => {
    const effectiveRightIconClick = onClick ? null : rightIconClick;
    const effectiveLeftIconClick = onClick ? null : leftIconClick;

    const content = (
        <ButtonContent
            type={type}
            iconType={iconType}
            leftIcon={leftIcon}
            icon={icon}
            rightIcon={rightIcon}
            children={size === "circle" ? null : children}
            size={size}
            rightIconClick={effectiveRightIconClick}
            leftIconClick={effectiveLeftIconClick}
            onClick={onClick}
            variant={variant}
            buttonStyle={buttonStyle}
            iconVariant={iconVariant}
            leftIconVariant={leftIconVariant}
            rightIconVariant={rightIconVariant}
        />
    );

    const button = (
        <Buttonpl
            type={type}
            variant={variant}
            buttonStyle={buttonStyle}
            style={{ cursor: onClick || type === "submit" ? "pointer" : "inherit" }}
            icon={icon}
            size={size}
            onClick={onClick}
            {...props}
        >
            {tooltip ? (
                <TooltipOverlay placement="bottom" tooltip={tooltip}>
                    {content}
                </TooltipOverlay>
            ) : (
                content
            )}
        </Buttonpl>
    );

    return size === "circle" ? (
        <ButtonWrapper>
            {button}
            {children && <span>{children}</span>}
        </ButtonWrapper>
    ) : (
        button
    );
};

export default ButtonWithText;
