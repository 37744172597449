import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ExtensionInputGroup, { StyledLabel } from "./ExtensionInputGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { colorArray, tagOptions } from "../utils/constants";
import { colors } from "app/popleads/components/ui-kit/colors";
import { HexColorPicker } from "react-colorful";
import { Body1 } from "app/popleads/components/ui-kit/Text";
import ExtensionSearch from "./ExtensionSearch";
import useGorevEkle from "app/popleads/hooks/useGorevEkle";
import { toast } from "react-toastify";
import moment from "moment";
import { useExtensionContext } from "../ExtensionContext";
import { StyledCheckbox } from "app/popleads/components/ui-kit/Select";
import { useSelector } from "react-redux";
import RichTextEditor from "app/popleads/components/ui-kit/RichTextEditor";
import useTaskUpdate from "app/popleads/hooks/useTaskUpdate";

const StyledContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    font-family: "Poppins_PL";
    font-weight: normal;
    position: relative;
`;
const StyledNewTaskContainer = styled("div")`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ColorsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 20px);
    align-items: center;
    grid-column-gap: 11px;
    grid-row-gap: 10px;
`;
const ColorCircle = styled.div`
    width: ${(props) => (props.selected ? "12px" : "20px")};
    height: ${(props) => (props.selected ? "12px" : "20px")};
    border-radius: 50%;
    background-color: ${(props) => props.color};
    cursor: pointer;
    position: relative;
`;
const ColorItem = styled.div`
    border: ${(props) => (props.selected ? `1px solid ${colors.primary[900]}` : " ")};
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    align-items: center;
    border-radius: 50%;
`;
const AddColorButton = styled.div`
    width: 12px;
    height: 12px;
    cursor: pointer;
`;

const StyledColorPickerContainer = styled.div`
    width: max-content;
    border: 1px solid #ffff !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 8px #49698c1a !important;
    padding: 16px;
    .react-colorful__pointer {
        width: 12px;
        height: 12px;
    }
`;
const StyledHexLabel = styled(Body1)`
    color: ${colors.neutral[900]};
`;
const StyledHexValue = styled(Body1)`
    color: #1e416e;
`;
const StyledHexContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 12px;
`;
const StyledColorPickerButtonContainer = styled(StyledHexContainer)`
    border-top: 1px solid ${colors.passive[100]};
    margin-top: 16px;
    padding-top: 12px;
`;
const StyledCheckboxContainer = styled("div")`
    display: flex;
    gap: 14px;
    align-items: center;
`;
const StyledCheckboxLabel = styled("label")`
    margin: 0;
    font-size: 0.91563rem;
    font-weight: 400;
    line-height: 1.2938rem;
`;

export const StyledFooterButton = styled("div")`
    position: absolute;
    z-index: 224123;
    background: #fff;
    width: inherit;
    display: flex;
    width: 410px;
    justify-content: end;
    padding: 12px;
    bottom: 0;
    border-top: 1px solid ${colors.passive[100]};
`;

const validationSchema = Yup.object().shape({
    important: Yup.boolean(),
    customerHapiness: Yup.boolean(),
    assignToPerson: Yup.array().nullable(),
    assignToActivity: Yup.string().nullable(),
    taskType: Yup.string().required("Task type is required"),
    relatedCustomer: Yup.string().nullable(),
    dateTime: Yup.string().required("Date and time is required"),
    description: Yup.string().required("Description is required"),
});

const ExtensionNewTask = () => {
    const [color, setColor] = useState();
    const [colorList, setColorList] = useState(colorArray);
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [showActivitySelect, setShowActivitySelect] = useState(false);

    const { gorevEkle } = useGorevEkle();
    const pickerRef = useRef();
    const {
        profile,
        companyUsers,
        userActivities,
        fetchUserExtensionActivitiesFunc,
        taskPage,
        selectedTask,
        editMode,
    } = useExtensionContext();

    const initialValues = editMode
        ? {
              important: selectedTask?.important || false,
              assignToPerson:
                  selectedTask?.assignedToIds?.map((id) => ({
                      label: companyUsers.find((user) => user.id === id)?.name,
                      value: id,
                  })) || [],
              assignToActivity: selectedTask?.activityId
                  ? {
                        label: userActivities.find((activity) => activity.id === selectedTask.activityId)?.subject,
                        value: selectedTask.activityId,
                    }
                  : "",
              taskType: selectedTask?.type || "",
              relatedCustomer: selectedTask?.relatedCustomer || "",
              dateTime: selectedTask?.zaman ? moment(selectedTask.zaman).format("YYYY-MM-DDTHH:mm") : "",
              description: selectedTask?.metin || "",
          }
        : {
              important: false,
              assignToPerson: [],
              assignToActivity: "",
              taskType: "",
              relatedCustomer: "",
              dateTime: "",
              description: "",
          };
    const [selectedColor, setSelectedColor] = useState(editMode ? selectedTask?.taskColour : "");

    const { user } = useSelector((state) => state.auth);
    const { updateTask } = useTaskUpdate();

    const editorConfig = {
        toolbar: ["bold", "italic", "bulletedList", "numberedList"],
        link: {
            addTargetToExternalLinks: true,
        },
    };

    const handleSaveColor = () => {
        if (!colorList.includes(color)) {
            setColorList([...colorList, color]);
            setSelectedColor(color);
        }
        setOpenColorPicker(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setOpenColorPicker(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showActivitySelect && userActivities.length === 0) {
            fetchUserExtensionActivitiesFunc();
        }
    }, [showActivitySelect]);
    // todo : hata mesajı toastta çıksın
    const handleSubmit = async (values, { setSubmitting, resetForm, validateForm }) => {
        try {
            // Validate form
            const errors = await validateForm(values);
            if (Object.keys(errors).length > 0) {
                const firstError = Object.values(errors)[0];
                toast.error(firstError);
                return;
            }

            setSubmitting(true);
            const payload = {
                metin: values.description || "",
                zaman: values.dateTime ? moment(values.dateTime).valueOf() : moment().valueOf(),
                profilId: profile?.id || "",
                guncelleProfilId: taskPage ? (values.relatedCustomer === profile?.id ? profile.id : "") : profile.id,
                type: values.taskType,
                important: values.important ? 1 : 0,
                taskColour: hexToRgb(selectedColor),
                assignedToIds: values.assignToPerson?.length > 0 ? values.assignToPerson.map((item) => item.value) : [],
                activityId: values.assignToActivity?.value || "",
                customerHappiness: values.customerHappiness ? 1 : 0,
            };
            if (editMode) {
                await updateTask(payload);
            } else {
                await gorevEkle(payload);
            }
            resetForm();
            setSelectedColor("");
        } catch (error) {
            // console.error("Task submission error:", error);
        } finally {
            setSubmitting(false);
        }
    };

    function hexToRgb(hex) {
        if (!hex || typeof hex !== "string" || hex.length < 6) {
            return "rgb(0, 0, 0)";
        }
        hex = hex.replace("#", "");
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);
        return `rgb(${r}, ${g}, ${b})`;
    }
    const customerFollowUpsPermission = user?.permissions?.CUSTOMER_HAPPINESS_TASK === 1;

    return (
        <StyledContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ setFieldValue, values, errors, touched, handleSubmit, validateForm, setTouched, setErrors }) => {
                    const onSubmit = async (e) => {
                        e.preventDefault();

                        // Set all fields as touched
                        const touchedFields = Object.keys(initialValues).reduce((acc, key) => {
                            acc[key] = true;
                            return acc;
                        }, {});
                        await setTouched(touchedFields);

                        // Validate all fields
                        const validationErrors = await validateForm();
                        if (Object.keys(validationErrors).length > 0) {
                            setErrors(validationErrors);
                            const firstError = Object.values(validationErrors)[0];
                            toast.error(firstError);
                            return;
                        }

                        handleSubmit(e);
                    };

                    return (
                        <Form onSubmit={onSubmit}>
                            <StyledNewTaskContainer>
                                <StyledCheckboxContainer>
                                    <StyledCheckbox
                                        id="important"
                                        onChange={(e) => {
                                            e.persist();
                                            setFieldValue("important", e.target.checked);
                                        }}
                                        checked={values.important}
                                    />
                                    <StyledCheckboxLabel htmlFor="important">Important</StyledCheckboxLabel>
                                </StyledCheckboxContainer>

                                {customerFollowUpsPermission && (
                                    <StyledCheckboxContainer>
                                        <StyledCheckbox
                                            id="customerHappiness"
                                            onChange={(e) => {
                                                e.persist();
                                                setFieldValue("customerHappiness", e.target.checked);
                                            }}
                                            checked={values.customerHapiness}
                                        />
                                        <StyledCheckboxLabel htmlFor="customerHappiness">
                                            Customer Follow Ups
                                        </StyledCheckboxLabel>
                                    </StyledCheckboxContainer>
                                )}

                                <ExtensionInputGroup
                                    label="Task Type"
                                    type="select"
                                    options={tagOptions}
                                    onChange={(selected) => {
                                        setFieldValue("taskType", selected?.value);
                                    }}
                                    value={tagOptions.find((option) => option.value === values.taskType)}
                                />
                                {taskPage && (
                                    <div>
                                        <StyledLabel>Releated Customer</StyledLabel>
                                        <ExtensionSearch
                                            optionClick={(selected) => setFieldValue("relatedCustomer", selected)}
                                        />
                                    </div>
                                )}

                                <ExtensionInputGroup
                                    label="Set Date & Time"
                                    type="date"
                                    timeStamp={true}
                                    onChange={(date) => setFieldValue("dateTime", date)}
                                    value={values.dateTime}
                                />
                                <div>
                                    <StyledLabel>Description</StyledLabel>
                                    <RichTextEditor
                                        minHeight={"150px"}
                                        maxHeight={"150px"}
                                        config={editorConfig}
                                        data={values.description}
                                        onChange={(event, editor) => {
                                            const editorData = editor.getData();
                                            setFieldValue("description", editorData);
                                        }}
                                    />
                                </div>
                                {taskPage ? (
                                    showActivitySelect ? (
                                        <ExtensionInputGroup
                                            key={"input_1"}
                                            label="Choose an Activity (optional)"
                                            type="select"
                                            defaultOptions={userActivities
                                                .map((item) => {
                                                    return {
                                                        label: item.subject,
                                                        value: item.id,
                                                    };
                                                })
                                                .slice(0, 10)}
                                            loadOptions={(input, callback) => {
                                                callback(
                                                    userActivities
                                                        .filter((activity) =>
                                                            activity.subject.toLowerCase().includes(input.toLowerCase())
                                                        )
                                                        .map((item) => {
                                                            return {
                                                                label: item.subject,
                                                                value: item.id,
                                                            };
                                                        })
                                                        .slice(0, 10)
                                                );
                                            }}
                                            onChange={(selected) => setFieldValue("assignToActivity", selected)}
                                            value={values.assignToActivity}
                                        />
                                    ) : (
                                        <ExtensionInputGroup
                                            key={"input_22"}
                                            label="Assign to Another Person (optional)"
                                            type="select"
                                            defaultOptions
                                            isMulti
                                            loadOptions={(input, callback) =>
                                                callback(
                                                    companyUsers
                                                        .filter((user) =>
                                                            user.name.toLowerCase().includes(input.toLowerCase())
                                                        )
                                                        .map((item) => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id,
                                                            };
                                                        })
                                                        .slice(0, 10)
                                                )
                                            }
                                            onChange={(selected) => setFieldValue("assignToPerson", selected)}
                                            value={values.assignToPerson}
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                                {taskPage && (
                                    <StyledCheckboxContainer>
                                        <StyledCheckbox
                                            id="activity-check"
                                            onChange={(e) => {
                                                e.persist();
                                                setShowActivitySelect((prev) => !prev);
                                            }}
                                            checked={showActivitySelect}
                                        />
                                        <StyledCheckboxLabel htmlFor="activity-check">
                                            {"Assign to an Activity"}
                                        </StyledCheckboxLabel>
                                    </StyledCheckboxContainer>
                                )}

                                <div>
                                    <StyledLabel>Pick Color</StyledLabel>
                                    <ColorsGrid>
                                        {colorList.map((color, index) => (
                                            <ColorItem key={index} selected={selectedColor === color}>
                                                <ColorCircle
                                                    selected={selectedColor === color}
                                                    color={color}
                                                    onClick={() => setSelectedColor(color)}
                                                />
                                            </ColorItem>
                                        ))}
                                        <AddColorButton onClick={() => setOpenColorPicker(true)}>+</AddColorButton>
                                    </ColorsGrid>
                                </div>
                            </StyledNewTaskContainer>
                            {openColorPicker && (
                                <StyledColorPickerContainer ref={pickerRef}>
                                    <HexColorPicker color={color} onChange={setColor} />
                                    <StyledHexContainer>
                                        <StyledHexLabel>Hex :</StyledHexLabel>
                                        <StyledHexValue>{color}</StyledHexValue>
                                    </StyledHexContainer>
                                    <StyledColorPickerButtonContainer>
                                        <ButtonWithText
                                            onClick={() => setOpenColorPicker(false)}
                                            size="small"
                                            variant="passive"
                                            buttonStyle="borderless"
                                        >
                                            Cancel
                                        </ButtonWithText>
                                        <ButtonWithText
                                            size="small"
                                            variant="success"
                                            type="default"
                                            buttonStyle="default"
                                            onClick={handleSaveColor}
                                        >
                                            Save
                                        </ButtonWithText>
                                    </StyledColorPickerButtonContainer>
                                </StyledColorPickerContainer>
                            )}
                            <StyledFooterButton>
                                <ButtonWithText size="xsmall" variant="primary" buttonStyle="default" type="submit">
                                    {editMode ? " Edit Task" : "Add Task"}
                                </ButtonWithText>
                            </StyledFooterButton>
                        </Form>
                    );
                }}
            </Formik>
        </StyledContainer>
    );
};

export default ExtensionNewTask;
