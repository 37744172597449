import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { colors } from "app/popleads/components/ui-kit/colors";
import SelectableTaskCard from "app/popleads/components/ui-kit/SelectableTaskCard";
import DateHeader from "app/popleads/components/ui-kit/DateHeader";
import moment from "moment";
import { StyledFooterButton } from "./ExtensionNewTask";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import Switch from "app/popleads/components/ui-kit/Switch";
import useCompletedTasks from "app/popleads/hooks/useGetCompletedTasks";
import { useExtensionContext } from "../ExtensionContext";

const TaskListContainer = styled.div`
    height: calc(100% - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 0px 12px;
`;
const StyledButtonContainer = styled(StyledFooterButton)`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: inherit;
`;

const DashedDivider = styled.hr`
    border: none;
    border-top: 1px dashed ${colors.neutral[200]};
    margin: 16px 0 8px 0;
`;

const TimeDisplay = styled.div`
    color: ${colors.neutral[700]};
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px;
`;

const NoTasksMessage = styled.div`
    text-align: center;
    padding: 20px;
    color: ${colors.neutral[500]};
`;

const StyledText = styled.div`
    color: ${colors.neutral[900]};
    font-size: 14px;
`;
const StyledFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;
const StyledTaskContainer = styled.div`
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 20px;
    }
`;

const TimeGroup = styled.div`
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ExtensionTaskList = ({ taskList, taskListLoading, setTaskToDelete, isActive, setIsActive }) => {
    const { getCompletedTasks } = useCompletedTasks();
    const { selectedDate } = useExtensionContext();
    const [tasks, setTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);

    useEffect(() => {
        const handleTasks = async () => {
            if (isActive) {
                const completedTasksData = await getCompletedTasks({ selectedDate });
                if (completedTasksData?.completedTasks?.length > 0) {
                    const sortedTasks = sortTasksByTime(completedTasksData.completedTasks);
                    setTasks(sortedTasks);
                    setSelectedTasks(sortedTasks.map((task) => task.id));
                } else {
                    setTasks([]);
                    setSelectedTasks([]);
                }
            } else if (taskList?.gorev?.length > 0) {
                const sortedTasks = sortTasksByTime(taskList.gorev);
                setTasks(sortedTasks);
                setSelectedTasks([]);
            } else {
                setTasks([]);
                setSelectedTasks([]);
            }
        };

        handleTasks();
    }, [isActive, selectedDate, taskList?.gorev]);

    const sortTasksByTime = (tasksToSort) => {
        return [...tasksToSort].sort((a, b) => {
            const timeA = moment(a.zaman).format("HH:mm");
            const timeB = moment(b.zaman).format("HH:mm");

            const [hoursA, minutesA] = timeA.split(":").map(Number);
            const [hoursB, minutesB] = timeB.split(":").map(Number);

            const timeAMinutes = hoursA * 60 + minutesA;
            const timeBMinutes = hoursB * 60 + minutesB;

            return timeAMinutes - timeBMinutes;
        });
    };

    const groupTasksByTime = (tasks) => {
        const groups = {};
        tasks.forEach((task) => {
            const timeKey = moment(task.zaman).format("HH:mm");
            if (!groups[timeKey]) {
                groups[timeKey] = [];
            }
            groups[timeKey].push(task);
        });
        return groups;
    };

    const handleSwitchChange = (value) => {
        setIsActive(value);
    };

    const highlightedDates = useMemo(() => {
        if (!taskList?.gorev) return [];
        return taskList.gorev.map((task) => moment(task.createdTime).format("YYYY-MM-DD"));
    }, [taskList?.gorev]);

    return (
        <>
            <TaskListContainer>
                <DateHeader highlightedDates={!taskListLoading ? highlightedDates : undefined} />
                {taskListLoading ? (
                    <LoadingContainer>
                        <Spinner animation="border" variant="primary" />
                    </LoadingContainer>
                ) : !tasks.length ? (
                    <NoTasksMessage>No tasks found</NoTasksMessage>
                ) : (
                    <div className="mb-13">
                        {Object.entries(groupTasksByTime(tasks)).map(([time, groupTasks], groupIndex) => (
                            <TimeGroup key={time}>
                                <DashedDivider />
                                <TimeDisplay>{time}</TimeDisplay>
                                {groupTasks.map((task) => (
                                    <StyledTaskContainer key={task.id}>
                                        <SelectableTaskCard
                                            task={task}
                                            setTaskToDelete={setTaskToDelete}
                                            selectedTasks={selectedTasks}
                                            setSelectedTasks={setSelectedTasks}
                                        />
                                    </StyledTaskContainer>
                                ))}
                            </TimeGroup>
                        ))}
                    </div>
                )}
            </TaskListContainer>
            <StyledButtonContainer>
                <ButtonWithText size="small" variant="danger" buttonStyle="borderless">
                    Show All Overdues
                </ButtonWithText>
                <StyledFlex>
                    <StyledText>{isActive ? "Only Completed" : "Only Current"}</StyledText>
                    <Switch isActive={isActive} setIsActive={handleSwitchChange} />
                </StyledFlex>
            </StyledButtonContainer>
        </>
    );
};

export default ExtensionTaskList;
