import FeatherIcon from "feather-icons-react";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { colors } from "./colors";
import { Body1 } from "./Text";
import { enUS } from "date-fns/locale";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";

const variantStyles = {
    normal: css`
        border: 1px solid ${colors.passive[300]};
        color: ${colors.passive[500]};
    `,
    focus: css`
        color: ${colors.primary[900]};
        border: 2px solid ${colors.primary[900]};
        padding: 10px 14px;
    `,
    filled: css`
        color: ${colors.primary[900]};
        border: 1px solid ${colors.passive[300]};
    `,
    disabled: css`
        border-color: ${colors.neutral[300]};
        color: ${colors.neutral[300]};
        background-color: #f6f8f9;
    `,
    error: css`
        border-color: #e74c3c;
        color: #e74c3c;
    `,
};

const DatePickerWrapper = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.neutral[500]};
    border-radius: 8px;
    padding: 10px 15px;
    height: 41px;
    font-size: 16px;
    input {
        &::placeholder {
            color: ${colors.neutral[500]};
        }
        color: ${colors.neutral[800]};
    }
    .react-datepicker {
        font-weight: 500;
        border-radius: 8px;
        color: ${colors.neutral[900]} !important;
        border: 1px solid ${colors.white} !important;
        box-shadow: 0px 2px 8px rgba(73, 105, 140, 0.1);
        right: 56px;
        font-family: "Poppins_PL";
    }
    .react-datepicker__month-container {
        padding: 10px;
    }
    .react-datepicker-popper {
        margin-top: 5px;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__header {
        border-bottom: none;
        background-color: ${colors.white};
        .react-datepicker__navigation-icon::before {
            color: ${colors.neutral[900]};
        }
        .react-datepicker__current-month {
            color: ${colors.neutral[900]};
            font-size: 14px;
            font-weight: normal;
            margin-top: 10px;
            margin-bottom: 18px;
        }
        .react-datepicker__day-name {
            color: ${colors.neutral[700]};
            font-size: 12px;
            width: 32px;
            margin: 0;
            height: 32px;
        }
    }
    .react-datepicker__time-container {
        width: 93px;
        border-radius: 4px;
        .react-datepicker__time-list {
            margin-top: 10px !important;
        }
        .react-datepicker-time__header {
            display: none;
        }
        .react-datepicker__time-list-item {
            margin-right: 3px;
            padding: 0 !important;
            display: flex;
            font-size: 12px !important;
            height: 19px !important;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            &:hover {
                color: ${colors.neutral[900]} !important;
                background-color: #f6f8f9 !important;
                border-radius: 4px;
                color: ${colors.neutral[900]};
                font-size: 12px;
                font-weight: 500;
            }
        }
        .react-datepicker__time-list-item--selected {
            color: ${colors.primary[50]} !important;
            background-color: ${colors.primary[900]} !important;
            border-radius: 4px !important;
            font-weight: normal !important;
        }
        .react-datepicker__time-list::-webkit-scrollbar {
            width: 8px;
        }
        .react-datepicker__time-list::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        .react-datepicker__time-list::-webkit-scrollbar-thumb {
            background: ${colors.passive[500]};
            border-radius: 10px;
        }
    }
    .react-datepicker__day--today {
        background: none;
        color: ${colors.neutral[900]} !important;
        font-size: 12px;
    }
    .react-datepicker__day--selected {
        color: ${colors.primary[50]} !important;
        background-color: ${colors.primary[900]};
        border-radius: 50%;
    }

    .react-datepicker__day:hover {
        color: ${colors.neutral[900]} !important;
        background-color: #f6f8f9 !important;
        border-radius: 50%;
    }

    .react-datepicker__day {
        color: ${colors.neutral[900]};
        font-size: 12px;
        width: 26px;
        height: 26px;
        margin: 4px;
    }
    .react-datepicker__day--outside-month {
        color: ${colors.neutral[500]};
        font-size: 12px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container {
        width: 100%;

        input {
            width: 100%;
        }
    }
    .react-datepicker__header,
    .react-datepicker__header--custom {
        padding: 0;
    }
    ${({ variant }) => variantStyles[variant]}
    .react-datepicker__day--highlighted {
        color: ${colors.warning[900]};
        background-color: transparent !important;
        border-bottom: 1px solid ${colors.warning[900]} !important;
        border-radius: 0 !important;
        font-weight: 500;

        &:hover {
            border-radius: 0 !important;
            background-color: transparent !important;
            color: ${colors.warning[900]};
        }
    }
`;

const UnStyledDatePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    .react-datepicker {
        font-weight: 500;
        border-radius: 8px;
        color: ${colors.neutral[900]} !important;
        border: 1px solid ${colors.white} !important;
        box-shadow: 0px 2px 8px rgba(73, 105, 140, 0.1);
        font-family: "Poppins_PL";
    }
    .react-datepicker__month-container {
        padding: 10px;
    }
    .react-datepicker-popper {
        margin-top: 5px;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__header {
        border-bottom: none;
        background-color: ${colors.white};
        .react-datepicker__navigation-icon::before {
            color: ${colors.neutral[900]};
        }
        .react-datepicker__current-month {
            color: ${colors.neutral[900]};
            font-size: 14px;
            font-weight: normal;
            margin-top: 10px;
            margin-bottom: 18px;
        }
        .react-datepicker__day-name {
            color: ${colors.neutral[700]};
            font-size: 12px;
            width: 32px;
            margin: 0;
            height: 32px;
        }
    }
    .react-datepicker__time-container {
        width: 93px;
        border-radius: 4px;
        .react-datepicker__time-list {
            margin-top: 10px !important;
        }
        .react-datepicker-time__header {
            display: none;
        }
        .react-datepicker__time-list-item {
            padding: 0 !important;
            display: flex;
            font-size: 12px !important;
            height: 19px !important;
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            &:hover {
                color: ${colors.neutral[900]} !important;
                background-color: #f6f8f9 !important;
                border-radius: 4px;
                color: ${colors.neutral[900]};
                font-size: 12px;
                font-weight: 500;
            }
        }
        .react-datepicker__time-list-item--selected {
            color: ${colors.primary[50]} !important;
            background-color: ${colors.primary[900]} !important;
            border-radius: 4px !important;
            font-weight: normal !important;
        }
        .react-datepicker__time-list::-webkit-scrollbar {
            width: 8px;
        }
        .react-datepicker__time-list::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        .react-datepicker__time-list::-webkit-scrollbar-thumb {
            background: ${colors.passive[500]};
            border-radius: 10px;
        }
    }
    .react-datepicker__day--today {
        background: none;
        color: ${colors.neutral[900]} !important;
        font-size: 12px;
    }
    .react-datepicker__day--selected {
        color: ${colors.primary[50]} !important;
        background-color: ${colors.primary[900]};
        border-radius: 50%;
    }

    .react-datepicker__day:hover {
        color: ${colors.neutral[900]} !important;
        background-color: #f6f8f9 !important;
        border-radius: 50%;
    }

    .react-datepicker__day {
        color: ${colors.neutral[900]};
        font-size: 12px;
        width: 26px;
        height: 26px;
        margin: 4px;
    }
    .react-datepicker__day--outside-month {
        color: ${colors.neutral[500]};
        font-size: 12px;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .special-day {
        background-color: black;
    }
    .react-datepicker__input-container {
        width: 100%;

        input {
            width: 100%;
        }
    }
    .react-datepicker__header,
    .react-datepicker__header--custom {
        padding: 0;
    }
    ${({ variant }) => variantStyles[variant]}
    .react-datepicker__day--highlighted {
        color: ${colors.warning[900]} !important;
        background-color: transparent !important;
        border-bottom: 1px solid ${colors.warning[900]} !important;
        border-radius: 0 !important;
        font-weight: 500;

        &:hover {
            border-radius: 0 !important;
            background-color: transparent !important;
            color: ${colors.warning[900]};
        }
    }
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    color: ${colors.neutral[500]};
    text-color: ${colors.neutral[500]};
`;

const UnStyledDatePicker = styled(DatePicker)`
    width: 100%;
    background-color: inherit;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    color: ${colors.neutral[900]};
    font-size: 18px;
    font-weight: 500;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    svg {
        color: ${({ filled, focus }) =>
            filled ? `${colors.primary[900]}` : focus ? `${colors.primary[900]}` : `${colors.neutral[500]}`};

        &:hover {
            color: ${colors.primary[900]};
        }
    }
`;

const UnStyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;

    svg {
        color: ${colors.neutral[500]};
    }
`;
const StyledDatePickerHeaderButton = styled("div")`
    background: "none";
    border: "none";
    cursor: "pointer";
    font-size: "18px";
    color: ${colors.neutral[900]};
`;
const StyledDatePickerHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 6px;
    margin-bottom: 16px;
`;
const StyledMonthName = styled(Body1)`
    font-family: "Poppins_PL";
    font-weight: normal;
`;
export const Seperator = styled.div`
    width: 1px;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    background-color: ${({ isDisabled }) => (isDisabled ? `${colors.neutral[500]}` : `${colors.neutral[300]}`)};
`;

const CustomDatePicker = ({
    timeStamp,
    leftIcon,
    rightIcon,
    disabled,
    value,
    onChange,
    variant,
    highlightedDates,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const datePickerRef = useRef();

    let status = "normal";

    if (disabled) {
        status = "disabled";
    } else if (props.error) {
        status = "error";
    } else if (isFocused) {
        status = "focus";
    } else if (value) {
        status = "filled";
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    // const handleDayClassName = (date, highligtedDates) => {
    //     console.log("burası çalıştı");

    //     const isSpecialDay = highligtedDates.some(
    //         (specialDate) =>
    //             specialDate.getFullYear() === date.getFullYear() &&
    //             specialDate.getMonth() === date.getMonth() &&
    //             specialDate.getDate() === date.getDate()
    //     );

    const highlightedDateObjects = highlightedDates?.map((date) => new Date(date)) || [];

    return variant !== "unstyled" ? (
        <DatePickerWrapper ref={datePickerRef} variant={status} disabled={disabled} {...props}>
            <>
                <IconContainer
                    filled={status === "filled"}
                    focus={isFocused}
                    aria-label="search-click"
                    data-testid="left-icon"
                    className="inputLeftIcon"
                    onClick={props.leftIconClick}
                    tabIndex="0"
                >
                    <SVG title="Emoticon" src={toAbsoluteUrl("/media/svg/popleads/calendar(1).svg")} />
                </IconContainer>
                <Seperator />
            </>
            <StyledDatePicker
                // dayClassName={(date) => handleDayClassName(date, highligtedDates)}
                selected={value ? new Date(value) : null}
                showTimeSelect={timeStamp}
                timeFormat="HH:mm"
                locale={enUS}
                dateFormat="dd/MM/yyyy HH:mm"
                timeIntervals={60}
                onChange={(date) => onChange(date.getTime())}
                placeholderText={props.placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onClickOutside={() => setIsFocused(false)}
                useWeekdaysShort={true}
                highlightDates={highlightedDateObjects}
                renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                    <StyledDatePickerHeader>
                        <StyledDatePickerHeaderButton onClick={decreaseMonth}>
                            <FeatherIcon aria-label="x" icon="chevron-left" size={20} />
                        </StyledDatePickerHeaderButton>
                        <StyledMonthName>
                            {monthDate.toLocaleString("en-US", { month: "long" })} {monthDate.getFullYear()}
                        </StyledMonthName>
                        <StyledDatePickerHeaderButton onClick={increaseMonth}>
                            <FeatherIcon aria-label="x" icon="chevron-right" size={20} />
                        </StyledDatePickerHeaderButton>
                    </StyledDatePickerHeader>
                )}
            />
            <IconContainer
                aria-label="x-click"
                data-testid="right-icon"
                className="inputRightIcon"
                onClick={props.rightIconClick}
            >
                {rightIcon && <FeatherIcon aria-label="x" icon={rightIcon} size={20} />}
            </IconContainer>
        </DatePickerWrapper>
    ) : (
        <UnStyledDatePickerWrapper ref={datePickerRef} {...props}>
            <UnStyledIconContainer
                filled={status === "filled"}
                focus={isFocused}
                aria-label="search-click"
                data-testid="left-icon"
                className="inputLeftIcon"
                onClick={props.leftIconClick}
                tabIndex="0"
            >
                <SVG title="Emoticon" src={toAbsoluteUrl("/media/svg/popleads/calendar(1).svg")} />
                {/* <FeatherIcon aria-label="search" color={colors.neutral[500]} icon={leftIcon} size={20} /> */}
            </UnStyledIconContainer>
            <UnStyledDatePicker
                selected={value ? new Date(value) : null}
                showTimeSelect={timeStamp}
                dateFormat="MMM d, yyyy - EEEE"
                timeIntervals={60}
                onChange={(date) => onChange(date.getTime())}
                placeholderText={props.placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onClickOutside={() => setIsFocused(false)}
                useWeekdaysShort={true}
                highlightDates={highlightedDateObjects}
                renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                    <StyledDatePickerHeader>
                        <StyledDatePickerHeaderButton onClick={decreaseMonth}>
                            <FeatherIcon aria-label="x" icon="chevron-left" size={20} />
                        </StyledDatePickerHeaderButton>
                        <StyledMonthName>
                            {monthDate.toLocaleString("en-US", { month: "long" })} {monthDate.getFullYear()}
                        </StyledMonthName>
                        <StyledDatePickerHeaderButton onClick={increaseMonth}>
                            <FeatherIcon aria-label="x" icon="chevron-right" size={20} />
                        </StyledDatePickerHeaderButton>
                    </StyledDatePickerHeader>
                )}
            />
        </UnStyledDatePickerWrapper>
    );
};

export default CustomDatePicker;
