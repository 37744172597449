import React from "react";
import { ExtensionCardHeader } from "./ExtensionCard";
import CustomDatePicker from "./DateInput";
import ButtonWithText from "./Button";
import { POPUP_CONTENTS } from "app/popleads/modules/extension/utils/constants";
import { useExtensionContext } from "app/popleads/modules/extension/ExtensionContext";

const EditableDateField = ({ value, onChange, highlightedDates }) => {
    return (
        <CustomDatePicker
            highlightedDates={highlightedDates}
            leftIcon={"calendar"}
            value={value}
            onChange={(date) => {
                onChange(date);
            }}
            variant="unstyled"
        />
    );
};

const DateHeader = ({ highlightedDates }) => {
    const { selectedDate, setState } = useExtensionContext();

    const handleDateChange = (date) => {
        setState({ selectedDate: date });
    };

    return (
        <ExtensionCardHeader
            cardTitle={
                <EditableDateField
                    value={selectedDate}
                    onChange={handleDateChange}
                    highlightedDates={highlightedDates}
                />
            }
            button={
                <ButtonWithText
                    onClick={() => {
                        setState({ popupContent: POPUP_CONTENTS.NEW_TASK, taskPage: true });
                    }}
                    buttonStyle="default"
                    icon="add"
                    iconType="material"
                    variant="primary"
                    type="default"
                    size="circle"
                />
            }
        />
    );
};

export default DateHeader;
