import React from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import { useExtensionContext } from "../ExtensionContext";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 8px 12px;
`;

const ExtensionNotesPage = () => {
    const { noteList } = useExtensionContext();

    return (
        <StyledContainer>
            <ExtensionCard cardItems={noteList} haveViewMore={false} variant="detailPage" />
        </StyledContainer>
    );
};

export default ExtensionNotesPage;
