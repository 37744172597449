import { useState } from "react";
import { post } from "../networking/RequestService";

function useProfileNoteActions() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const createNote = async (params = {}) => {
        setLoading(true);
        setError(null);

        if (!params?.profil || !params?.not) {
            const errorMessage = "Missing required parameters";
            setError(errorMessage);
            setLoading(false);
            return { error: errorMessage };
        }

        try {
            const response = await post({ fn: "notEkle", ...params });
            return { data: response.data };
        } catch (error) {
            const errorMessage = error?.response?.data?.text || error?.message || "An unexpected error occurred";
            setError(errorMessage);
            return { error: errorMessage };
        } finally {
            setLoading(false);
        }
    };

    const updateNote = async (params = {}) => {
        setLoading(true);
        setError(null);

        if (!params?.profil || !params?.note || !params?.text) {
            const errorMessage = "Missing required parameters";
            setError(errorMessage);
            setLoading(false);
            return { error: errorMessage };
        }

        try {
            const response = await post({ fn: "noteEdit", ...params });
            return { data: response.data };
        } catch (error) {
            const errorMessage = error?.response?.data?.text || error?.message || "An unexpected error occurred";
            setError(errorMessage);
            return { error: errorMessage };
        } finally {
            setLoading(false);
        }
    };

    const deleteNote = async (params = {}) => {
        setLoading(true);
        setError(null);

        if (!params?.profil || !params?.not) {
            const errorMessage = "Missing required parameters";
            setError(errorMessage);
            setLoading(false);
            return { error: errorMessage };
        }

        try {
            const response = await post({ fn: "notSil", ...params });
            return { data: response.data };
        } catch (error) {
            const errorMessage = error?.response?.data?.text || error?.message || "An unexpected error occurred";
            setError(errorMessage);
            return { error: errorMessage };
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, createNote, updateNote, deleteNote };
}

export default useProfileNoteActions;
