import React from "react";
import styled from "styled-components";
import { Body2, Heading4 } from "app/popleads/components/ui-kit/Text";
import { colors } from "app/popleads/components/ui-kit/colors";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import FeatherIcon from "feather-icons-react";
import ButtonWithText from "app/popleads/components/ui-kit/Button";

//todo : #00856A rengi yok onun yerine paid amounnta success kullandım
//todo: Activities Payment başlıklarında heading4 kullanıldı fakat düzeltilmeli
//todo: #00856C rengi olmadığı için right content farklı  TEKİN: #00856A olan success.900 kullanılabilir.
const cardItems = [
    {
        title: "Najm Company for Insurance Services",
        imgSrc: "https://c.popleads.com/l3rn-online/course/Audit-Regulation-and-Compliance.jpg",
    },
    {
        title: "Accounting for Non-Accountants",
        imgSrc: "https://c.popleads.com/l3rn-online/course/Audit-Regulation-and-Compliance.jpg",
    },
    {
        icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
        title: "Project Manager",
        content: "Close Date: Nov 30, 2024",
        rightContent: "$4,000",
        footer: "Marketing Channel: Social Media",
    },
    {
        icon: (
            <div>
                <FeatherIcon
                    strokeWidth="1px"
                    // data-testid={`feather-${leftIcon}`}
                    icon="thumbs-up"
                    size="16"
                />
            </div>
        ),
        title: "Project Manager",
        content: "Close Date: Nov 30, 2024",
        rightContent: "$4,000",
        footer: "Marketing Channel: Social Media",
    },
];
const activities = [
    { id: 1, activityName: "Invoice Requisiton", redirect: "" },
    { id: 1, activityName: "Proposal", redirect: "" },
    { id: 1, activityName: "Course Campaign", redirect: "" },
];
const paidAmounts = [
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
    { date: "Nov 12, 2024 09:30", amount: "$1,000" },
];
const options = [
    { label: "Yes", value: "Yes", activeVariant: "success" },
    { label: "No", value: "No", activeVariant: "neutral" },
];
const StyledTitle = styled(Heading4)`
    margin-bottom: 18px;
    color: ${colors.neutral[700]};
    display: block;
`;
const StyledContainer = styled("div")``;
const StyledContentText = styled(Body2)`
    color: ${colors.primary[900]};
`;

const StyledItemContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
const StyledPaidContainer = styled(StyledItemContainer)`
    align-items: start;
`;
const StyledDisabledText = styled(StyledContentText)`
    color: ${colors.neutral[700]};
`;
const StyledHorizontalLine = styled("div")`
    border-bottom: 1px solid ${colors.neutral[100]};
    margin-top: 12px;
    margin-bottom: 12px;
`;
const StyledAmountInfo = styled("div")`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: ${({ isLast }) => (isLast ? "0" : "12px")};
`;

const StyledPaidDate = styled(StyledContentText)`
    color: ${colors.neutral[800]};
`;
const StyledAmount = styled(StyledContentText)`
    color: ${colors.success[800]};
`;
const ButtonContiner = styled("div")`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const ExtensionViewDeal = () => {
    return (
        <StyledContainer>
            <ExtensionCard iconWidth="40px" variant="borderless" haveViewMore={false} cardItems={cardItems} />
            <StyledTitle>Activities</StyledTitle>
            {activities.map((activity, index) => (
                <StyledItemContainer key={index}>
                    <StyledContentText>{activity.activityName}</StyledContentText>
                    <div>
                        {" "}
                        <ButtonWithText
                            icon="external-link"
                            iconType="feather"
                            variant="primary"
                            size="circle"
                            type="default"
                        />
                    </div>
                </StyledItemContainer>
            ))}
            <StyledTitle>Payment</StyledTitle>
            <StyledItemContainer>
                <StyledDisabledText>VAT Included</StyledDisabledText>
                <Toggle
                    selected={"Yes"}
                    // setSelected={(viewType) => console.log("viewtype", viewType)}
                    options={options}
                />
            </StyledItemContainer>
            <StyledItemContainer>
                <StyledDisabledText>Selft Investment</StyledDisabledText>
                <Toggle
                    selected={"Yes"}
                    // setSelected={(viewType) => console.log("viewtype", viewType)}
                    options={options}
                />
            </StyledItemContainer>
            <StyledItemContainer>
                <StyledDisabledText>Paid</StyledDisabledText>
                <ButtonContiner>
                    <ButtonWithText
                        leftIcon="" //yes / no
                        icon="check"
                        iconType="feather"
                        variant="primary"
                        size="circle"
                        type="default"
                    />
                    <ButtonWithText icon="x" iconType="feather" variant="danger" size="circle" type="default" />
                </ButtonContiner>
            </StyledItemContainer>
            <StyledHorizontalLine />
            <StyledPaidContainer>
                <StyledDisabledText>Paid Amount</StyledDisabledText>
                <div>
                    {paidAmounts.map((paidAmount, index) => (
                        <StyledAmountInfo isLast={index + 1 === paidAmount.length} key={index}>
                            <StyledPaidDate>{paidAmount.date}</StyledPaidDate>
                            <StyledAmount>{paidAmount.amount}</StyledAmount>
                        </StyledAmountInfo>
                    ))}
                </div>
            </StyledPaidContainer>
            <StyledHorizontalLine />
            <StyledItemContainer>
                <StyledDisabledText>Total Paid</StyledDisabledText>
                <StyledAmount>1,000$</StyledAmount>
            </StyledItemContainer>
        </StyledContainer>
    );
};

export default ExtensionViewDeal;
