export const dealMarketingTypes = [
    "Marketing",
    "Cross-Sale",
    "Project Manager",
    "Tender",
    "E-Commerce",
    "Marketing Cross Sale",
    "Re-Seller Partnership",
    "Portfolio Sale",
];
export const dealMarketingTypesOptions = dealMarketingTypes.map((item) => ({ label: item, value: item }));

export const dealChannelTypes = [
    "Bayt",
    "Certifind",
    "Edarabia",
    "Eventbrite",
    "E-Shot",
    "Facebook Inquiry",
    "Find Courses",
    "Gulf Talent",
    "Key Stone Academic Solutions",
    "Laimoon",
    "Linkedin",
    "Nigerian Training and Seminars",
    "Partner Academies",
    "Phone Inquiries",
    "Social Media",
    "Speedy Course",
    "Web Inquiry",
    "Website's Chat Box",
    "WhatsApp",
    "Adwords Istizada",
    "Ta3leem",
    "Instagram",
    "Courseseekers",
    "E-Commerce",
    "Web Inquiry - Program Enrollment",
];
export const dealChannelTypesOptions = dealChannelTypes.map((item) => ({ label: item, value: item }));
export const colorArray = [
    "#081B26",
    "#384D56",
    "#6F8086",
    "#A7B2B7",
    "#286A99",
    "#3C9DCB",
    "#66B5D8",
    "#99CDE5",
    "#276A54",
    "#389D87",
    "#65B5A5",
    "#99CEC3",
    "#A24C28",
    "#D57F5A",
    "#DF9F84",
    "#EABFAD",
    "#B43636",
    "#E76969",
    "#ED8E8E",
    "#F3B4B4",
    "#7018AD",
    "#A33FE0",
    "#BA6EE8",
    "#D19EEF",
];
export const tagOptions = [
    {
        label: "Call",
        value: "Call",
    },
    {
        label: "Call Back",
        value: "Call Back",
    },
    {
        label: "Follow Up",
        value: "Follow Up",
    },
    {
        label: "Meeting",
        value: "Meeting",
    },
    {
        label: "Customer Chat",
        value: "Customer Chat",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const POPUP_CONTENTS = {
    ABOUT: "About",
    COURSES: "Courses",
    DEALS: "Deals",
    NEW_DEAL: "New Deal",
    NOTES: "Notes",
    NEW_TASK: "New Task",
    FEED: "Feed",
    NOTES_ATTACHMENT: "NotesAttachment",
};
