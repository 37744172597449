import { Formik, Form } from "formik";
import { colors } from "app/popleads/components/ui-kit/colors";
import React from "react";
import styled from "styled-components";
import ExtensionInputGroup from "./ExtensionInputGroup";
import Options from "app/popleads/components/ui-kit/Options";
import ExtensionNewInhouseDeal from "./ExtensionNewInhouseDeal";
import { useExtensionContext } from "../ExtensionContext";

export const StyledLabel = styled.label`
    display: block;
    font-size: 12px;
    color: ${colors.neutral[600]};
    margin-bottom: 0px;

    ${(props) =>
        props.required &&
        `
    &:after {
      content: '*';
      color: #ff4d4f;
      margin-left: 4px;
    }
  `}

    ${(props) =>
        props.disabled &&
        `
    color: #999;
    cursor: not-allowed;
  `}
`;

/*const validationSchema = Yup.object().shape({
    dealPotential: Yup.string(), //.required("Deal potential is required"),
    status: Yup.string(),
    campaignType: Yup.string().required("Campaign type is required"),
    campaign: Yup.string(),//.required("Campaign is required"),
    amount: Yup.number().required("Amount is required"),
    //.required("Amount is required")
    //.positive("Amount must be positive"),
    partner: Yup.string(), //.required("Partner is required"),
    numberOfUnits: Yup.string(),
    //.required("Number of units is required")
    //.positive("Number must be positive"),
    closingDate: Yup.string(), //.required("Closing date is required"),
    dealType: Yup.string(), //.required("Deal type is required"),
    certificateName: Yup.string(), //.required("Certificate name is required"),
    certificateSurname: Yup.string(), //.required("Certificate surname is required"),
    selfInvestment: Yup.boolean(), //.required("Self investment selection is required"),
    vatIncluded: Yup.string(), //.required("VAT included selection is required"),
    selectedInquiry: Yup.string(),
    dealName: Yup.string(),
    accountName: Yup.string(),
    courseName: Yup.string(),
    paidAmount: Yup.string(),
    type: Yup.string(),
    channelType: Yup.string(),
    paidDeal: Yup.string(),
});*/

const ExtensionNewDeal = () => {
    const { setDealObject, dealObject } = useExtensionContext();

    const initialValues = {
        dealPotential: 25,
        status: "",
        campaignType: "",
        campaign: "",
        amount: 0,
        partner: "",
        numberOfUnits: 1,
        closingDate: "",
        dealType: "",
        certificateName: "",
        certificateSurname: "",
        selfInvestment: true,
        vatIncluded: "No",
        selectedInquiry: "",
        dealName: "",
        accountName: "",
        courseName: "",
        paidAmount: "",
        type: "",
        channelType: "",
        paidDeal: "No",
    };

    const statusOptions = [
        { label: "WON", value: "WON", activeVariant: "success" },
        { label: "Lost", value: "Lost", activeVariant: "neutral" },
        { label: "Cancelled", value: "Cancelled", activeVariant: "neutral" },
    ];

    return (
        <Formik initialValues={initialValues}>
            {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
                return (
                    <Form style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <ExtensionInputGroup
                            label="Select the Deal Potential"
                            type="range"
                            options={{ min: 25, max: 75, step: 25 }}
                            onChange={(value) => {
                                if (dealObject.dealPotential === 75 && value !== 75 && dealObject.status === "WON") {
                                    setDealObject({ ...dealObject, status: "" });
                                    setFieldValue("status", "");
                                }
                                setFieldValue("dealPotential", value);
                                setDealObject({ ...dealObject, dealPotential: value });
                            }}
                            error={touched.dealPotential && errors.dealPotential}
                            value={dealObject.dealPotential}
                        />

                        <div className="d-flex align-items-center justify-content-between">
                            <StyledLabel>Status</StyledLabel>
                            <div className="d-flex" style={{ gap: "8px" }}>
                                <Options
                                    options={dealObject.dealPotential <= 50 ? statusOptions.slice(1) : statusOptions}
                                    selected={dealObject.status}
                                    setSelected={(value) => {
                                        setFieldValue("status", value);
                                        setDealObject({ ...dealObject, status: value });
                                    }}
                                />
                            </div>
                        </div>

                        <ExtensionInputGroup
                            label="Campaign Type"
                            type="select"
                            options={[
                                { label: "LVT Public", value: "LVT Public" },
                                { label: "Public", value: "Public" },
                                { label: "LVT In-house", value: "LVT In-house" },
                                { label: "In-house", value: "In-house" },
                            ]}
                            onChange={(selected) => {
                                setFieldValue("campaignType", selected.value);
                                setDealObject({ ...dealObject, campaignType: selected.value });
                            }}
                        />

                        {(values.campaignType === "In-house" || values.campaignType === "LVT In-house") && (
                            <ExtensionNewInhouseDeal
                                values={values}
                                setFieldValue={setFieldValue}
                                dealObject={dealObject}
                                setDealObject={setDealObject}
                            />
                        )}
                        {/*
                    <ExtensionInputGroup
                        label="Campaign"
                        type="select"
                        options={[
                            { label: "LVT Public", value: "LVT Public" },
                            { label: "Public", value: "Public" },
                        ]}
                    />
                    <ExtensionInputGroup label="Amount" type="input" inpType="number" leftIcon={"dollar-sign"} />
                    <ExtensionInputGroup
                        label="Partner"
                        type="select"
                        options={[
                            { label: "LVT Public", value: "LVT Public" },
                            { label: "Public", value: "Public" },
                        ]}
                    />
                    <ExtensionInputGroup label="Number of Units" type="incdec" />
                    <ExtensionInputGroup label="Set Closing Date" type="date" />
                    <ExtensionInputGroup
                        label="Deal Type"
                        type="select"
                        options={[
                            { label: "LVT Public", value: "LVT Public" },
                            { label: "Public", value: "Public" },
                        ]}
                    />
                    <ExtensionInputGroup label="Certificate Name" type="input" inpType="text" />
                    <ExtensionInputGroup label="Certificate Surname" type="input" inpType="text" />
                    <div className="d-flex align-items-center justify-content-between mt-5">
                        <StyledLabel>Self Investment</StyledLabel>
                        <div className="d-flex" style={{ gap: "8px" }}>
                            <Toggle
                                selected={"yes"}
                                // setSelected={(viewType) => console.log("viewtype", viewType)}
                                options={options}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-5">
                        <StyledLabel>VAT Included</StyledLabel>
                        <div className="d-flex" style={{ gap: "8px" }}>
                            <Toggle
                                selected={"yes"}
                                // setSelected={(viewType) => console.log("viewtype", viewType)}
                                options={options}
                            />
                        </div>
                    </div>
                    */}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ExtensionNewDeal;
