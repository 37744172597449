import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "./colors";
import { Body2, Heading3, Heading6 } from "app/popleads/components/ui-kit/Text";
import InputWithIcon from "./Input";
import { useExtensionContext } from "app/popleads/modules/extension/ExtensionContext";
import ButtonWithText from "./Button";

const Container = styled.div`
    display: flex;
`;

const ImageSection = styled.div`
    min-height: 88px;
    min-width: 88px;
    max-height: 88px;
    max-width: 88px;
    border-radius: 50%;
    padding: 1px;
    opacity: 1;
    background: transparent
        linear-gradient(
            90deg,
            ${colors.success[500]} 0%,
            #f6f8f914 37%,
            #f6f8f900 50%,
            #f6f8f914 63%,
            ${colors.success[500]} 100%
        )
        0% 0% no-repeat padding-box;
    text-align: center;
`;

const ImageBorder = styled.div`
    background-color: ${colors.neutral[50]};
    border-radius: 50%;
    padding: 4px;
    width: 100%;
    height: 100%;
`;

const Image = styled.img`
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    padding: 4px;
    border-radius: 50%;
    border: 1px solid ${colors.success[900]};
`;

const Star = styled.div`
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: #00856b;
    border-radius: 50%;
    color: #ffffff;
    position: relative;
    top: -23px;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
`;

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    color: ${({ inActive }) => (inActive ? colors.neutral[500] : colors.neutral[800])};
    justify-content: center;
    flex: 1;
    overflow: hidden;
    gap: 5px;
`;

const Name = styled(Heading3)`
    line-height: 1.5rem;
    color: ${colors.neutral[900]};
`;

const Title = styled(Heading6)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: default;
`;

const ProfileMenu = styled.div`
    padding: 0;
`;

function ProfileCard({ variant, ...props }) {
    const { profile, setState } = useExtensionContext();
    const fallbackSrc = "https://dev.popleads.com/PopleadsDevCRM1/img/profilepic.png";
    const [imgSrc, setImgSrc] = useState(profile.resim ? profile.resim : fallbackSrc);
    const [isHidden, setIsHidden] = useState("");

    const getLinkedinUsername = (site) => {
        const regex = /\/in\/[a-zA-Z0-9\-]+/;
        return site?.match(regex)[0];
    };

    return (
        <Container {...props}>
            <ImageSection>
                <ImageBorder>
                    <Image src={imgSrc} alt="profile" onError={() => setImgSrc(fallbackSrc)} />
                </ImageBorder>
                {profile.dealsWonCount > 0 && <Star className="material-symbols-outlined">star</Star>}
            </ImageSection>
            <ProfileInfo inActive={variant === "neutralInActive"}>
                <Name>
                    <EditableField
                        value={
                            profile.ad && profile.soyad ? `${profile.ad} ${profile.soyad}` : profile.ad || profile.soyad
                        }
                        field="ad"
                    />
                </Name>
                {variant === "neutralInActive" ? (
                    <Title>
                        <EditableField value={getLinkedinUsername(profile.site)} field="site" />
                    </Title>
                ) : (
                    <>
                        <Title>
                            {isHidden !== "sirket" && (
                                <span onClick={() => setIsHidden("baslik")}>
                                    <EditableField value={profile.sirket} field="sirket" setIsHidden={setIsHidden} />
                                </span>
                            )}
                            {!isHidden && <span className="mx-1">|</span>}
                            {isHidden !== "baslik" && (
                                <span onClick={() => setIsHidden("sirket")}>
                                    <EditableField value={profile.baslik} field="baslik" setIsHidden={setIsHidden} />
                                </span>
                            )}
                        </Title>
                        <Body2>
                            <EditableField value={profile.email} field="email" />
                        </Body2>
                        <Body2>
                            <EditableField value={profile.tel} field="tel" />
                        </Body2>
                    </>
                )}
            </ProfileInfo>
            {variant !== "neutralInActive" ? (
                <ProfileMenu>
                    <ButtonWithText
                        icon="more_vert"
                        iconType="material"
                        variant="passive"
                        size="medium"
                        buttonStyle="borderless"
                        onClick={() => {
                            setState({ popupContent: "About" });
                        }}
                    />
                </ProfileMenu>
            ) : null}
        </Container>
    );
}

export default ProfileCard;

function EditableField({ value, onChange, field, setIsHidden }) {
    const [isEditing, setIsEditing] = useState(false);
    const wrapperRef = useRef(null);
    const { handleUpdateProfile } = useExtensionContext();
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isEditing && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                const newValue = inputValue?.trim();
                if (!newValue || newValue === value) {
                    setInputValue(value); // değer değişmediyse orijinal değere geri dön
                    setIsEditing(false);
                    if (setIsHidden) setIsHidden("");
                    return;
                }

                handleUpdateProfile({ field: field, fieldValue: newValue }); // Parent bileşene değişikliği bildir
                setIsEditing(false);
                if (setIsHidden) setIsHidden("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditing, inputValue, value, onChange, field, handleUpdateProfile]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (isEditing) {
            const input = wrapperRef.current.querySelector("input");
            input.focus();
        }
    }, [isEditing]);

    return (
        <span ref={wrapperRef} style={{ cursor: "pointer" }}>
            {isEditing ? (
                <InputWithIcon
                    variant={"unstyled"}
                    debounceTime={0}
                    value={inputValue || ""}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            ) : (
                <span onClick={() => setIsEditing(true)}>{value || "-"}</span>
            )}
        </span>
    );
}
