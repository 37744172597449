import React from "react";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import SVG from "react-inlinesvg";
import moment from "moment";
import { POPUP_CONTENTS } from "../utils/constants";
import { useExtensionContext } from "../ExtensionContext";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { FormattedNumber } from "react-intl";
import * as util from "../../../app/functions/util";
import styled from "styled-components";
import { ExtensionBody, ExtensionSmall } from "app/popleads/components/ui-kit/Text";
import { colors } from "app/popleads/components/ui-kit/colors";

const StyledCardHeader = styled(ExtensionSmall)`
    color: ${colors.neutral[800]};
    display: flex;
`;

const StyledCardContent = styled(ExtensionBody)`
    color: ${colors.neutral[800]};
    display: flex;
`;

const StyledCardFooter = styled(ExtensionSmall)`
    color: ${colors.neutral[500]};
    display: flex;
`;

const ExtensionDealCard = () => {
    const { profile, setState } = useExtensionContext();

    const dealList = profile?.olanak.map((olanak) => {
        return {
            icon: <SVG src={toAbsoluteUrl("/media/svg/popleads/money_bag.svg")} />,
            header: <StyledCardHeader>{moment(olanak.createdTime).format("MMM DD, YYYY")}</StyledCardHeader>,
            content: (
                <StyledCardContent>
                    {olanak.campaign?.campaignTemplateCourseName ??
                        olanak.courseName ??
                        util.getDynamicValue(olanak.campaign?.columnsList, "Course Name") ??
                        olanak.campaign?.ad ??
                        "-"}
                </StyledCardContent>
            ),
            rightContent: (
                <StyledCardContent>
                    $<FormattedNumber minimumFractionDigits={0} maximumFractionDigits={2} value={olanak.totalAmount} />
                </StyledCardContent>
            ),
            footer: <StyledCardFooter>{olanak.ad}</StyledCardFooter>,
        };
    });

    return (
        <ExtensionCard
            button={
                <ButtonWithText
                    icon="add"
                    onClick={() => {
                        setState({ popupContent: POPUP_CONTENTS.NEW_DEAL });
                    }}
                    iconType="material"
                    variant="primary"
                    buttonStyle="default"
                    size="circle"
                />
            }
            cardTitle={"Deals"}
            cardItems={dealList}
            viewMoreOnClick={() => {
                setState({ popupContent: POPUP_CONTENTS.DEALS });
            }}
        />
    );
};

export default ExtensionDealCard;
