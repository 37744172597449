import { useEffect, useState } from "react";
import { post } from "../networking/RequestService";

const useGetProfile = (queryProfileId) => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProfile = async () => {
        if (!queryProfileId) return;

        setLoading(true);
        setError(null);
        try {
            const res = await post({
                fn: "getProfile",
                id: queryProfileId,
            });

            const profile = res.data.profile;
            profile.inhouseInquiries = profile.olanak
                .filter((item) => item.activityType === "In-house Inquiry")
                .map((item) => ({
                    label: item.activityName,
                    value: item.activityId,
                }));
            //console.log("profile", profile)

            setProfile(profile);
        } catch (err) {
            setError(err);
            setProfile(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [queryProfileId]);

    return { profile, loading, error, refetch: fetchProfile };
};

export default useGetProfile;
