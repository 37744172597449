import { colors } from "app/popleads/components/ui-kit/colors";
import CustomDatePicker from "app/popleads/components/ui-kit/DateInput";
import InputWithIcon from "app/popleads/components/ui-kit/Input";
import NumberOfUnits from "app/popleads/components/ui-kit/NumberOfUnits";
import RangeInput from "app/popleads/components/ui-kit/RangeInput";
import CustomSelect from "app/popleads/components/ui-kit/Select";
import TextAreaInput from "app/popleads/components/ui-kit/TextAreaInput";
import Toggle from "app/popleads/components/ui-kit/Toggle";
import React from "react";
import styled from "styled-components";

export const StyledLabel = styled.label`
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.neutral[900]};
    margin-bottom: 4px;

    ${(props) =>
        props.required &&
        `
    &:after {
      content: '*';
      color: #ff4d4f;
      margin-left: 4px;
    }
  `}

    ${(props) =>
        props.disabled &&
        `
    color: #999;
    cursor: not-allowed;
  `}
`;

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const ToggleLabel = styled.label`
    font-size: 12px;
    font-weight: 400;
    color: ${colors.neutral[600]};
    margin: 0;
`;

const ExtensionInputGroup = ({
    label,
    type = "range",
    value,
    onChange,
    placeholder,
    error,
    options,
    inpType,
    leftIcon,
    rightIcon,
    leftMaterialIcon,
    rightMaterialIcon,
    iconType,
    leftIconClick,
    rightIconClick,
    isClearable,
    isSearchable,
    size,
    timeStamp,
    isCreatable,
    loadOptions,
    cacheOptions,
    defaultOptions,
    isMulti,
}) => {
    return (
        <div>
            {type !== "toggle" && label && <StyledLabel>{label}</StyledLabel>}

            {type === "range" ? (
                <RangeInput selected={value} setSelected={onChange} options={options} />
            ) : type === "select" ? (
                <CustomSelect
                    key="single-select"
                    options={options}
                    cacheOptions={cacheOptions}
                    defaultOptions={defaultOptions}
                    value={value}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    isCreatable={isCreatable}
                    onChange={onChange}
                    isMulti={isMulti}
                    placeholder={placeholder ?? "Please select an option"}
                    loadOptions={loadOptions}
                />
            ) : type === "date" ? (
                <CustomDatePicker
                    timeStamp={timeStamp}
                    leftIcon={"calendar"}
                    placeholder="mm/dd/yyyy"
                    value={value}
                    onChange={onChange}
                />
            ) : type === "input" ? (
                <InputWithIcon
                    placeholder={placeholder ?? "Please enter a value"}
                    type={inpType}
                    leftIcon={leftIcon}
                    leftMaterialIcon={leftMaterialIcon}
                    iconType={iconType}
                    rightIcon={rightIcon}
                    rightMaterialIcon={rightMaterialIcon}
                    leftIconClick={leftIconClick}
                    rightIconClick={rightIconClick}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                />
            ) : type === "incdec" ? (
                <NumberOfUnits />
            ) : type === "toggle" ? (
                <ToggleWrapper>
                    <ToggleLabel>{label}</ToggleLabel>
                    <Toggle selected={value} setSelected={onChange} options={options} size={size} />
                </ToggleWrapper>
            ) : type === "textarea" ? (
                <TextAreaInput
                    id="textarea-input"
                    value={value}
                    onChange={onChange}
                    name="textarea-input"
                    rows="4"
                    cols="50"
                />
            ) : (
                <input type={type} value={value} onChange={onChange} placeholder={placeholder} />
            )}

            {error && <span className="error-message">{error}</span>}
        </div>
    );
};

export default ExtensionInputGroup;
