import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "./colors";
import { Heading6, Body2 } from "./Text";
import ButtonWithText from "./Button";
import { useExtensionContext } from "app/popleads/modules/extension/ExtensionContext";
import useTaskComplete from "app/popleads/hooks/useTaskComplete";
import useGetTaskLists from "app/popleads/hooks/useGetTaskLists";
import useTaskUpdate from "app/popleads/hooks/useTaskUpdate";
import moment from "moment";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { POPUP_CONTENTS } from "app/popleads/modules/extension/utils/constants";

const commonTextStyles = css`
    color: ${(props) => (props.selected ? colors.neutral[500] : colors.neutral[800])};
    font-size: 12px;
`;

const flexCenter = css`
    display: flex;
    align-items: center;
`;

const CardContainer = styled.div`
    background-color: ${(props) => (props.overdue ? "#FDF5F5" : colors.white)};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => (props.overdue ? "#F3B4B4" : colors.neutral[100])};
    position: relative;
    transition: all 0.2s ease-in-out;
    padding-bottom: 0;
    cursor: pointer;

    &:hover {
        padding-bottom: ${(props) => (props.isExpanded ? "40px" : "0")};

        .action-bar {
            visibility: ${(props) => (props.isExpanded ? "visible" : "hidden")};
            opacity: ${(props) => (props.isExpanded ? "1" : "0")};
            transform: ${(props) => (props.isExpanded ? "translateY(0)" : "translateY(100%)")};
        }
    }
`;

const SelectionCircle = styled.div`
    width: 20px;
    height: 20px;
    border: 2px solid ${(props) => (props.selected ? "#00856C" : colors.neutral[500])};
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    ${flexCenter};
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${(props) => (props.selected ? "#00856C" : "transparent")};
        transition: all 0.2s ease;
    }
`;

const CardContent = styled.div`
    padding: 8px;
    padding-left: 40px;
    position: relative;
`;

const DetailContent = styled.div`
    max-height: ${(props) => (props.isExpanded ? "500px" : "0")};
    opacity: ${(props) => (props.isExpanded ? "1" : "0")};
    overflow: hidden;
    transition: all 0.3s ease-in-out;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(Heading6)`
    color: ${(props) => (props.selected ? colors.neutral[500] : colors.neutral[800])};
    font-size: 12px;
    font-weight: 500;
    ${flexCenter};
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 8px;
`;

const TitleContent = styled.div`
    * {
        margin: 0;
        padding: 0;
    }
    display: flex;
    align-items: center;
`;

const ImportantIcon = styled.span`
    color: ${(props) => (props.important ? colors.danger[900] : colors.neutral[300])};
    font-size: 14px;
`;

const Description = styled.div`
    color: ${(props) => (props.selected ? colors.neutral[500] : colors.neutral[800])};
    font-size: 14px;
    font-weight: 400;
    margin: 8px 0;
`;

const UserInfoWrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: ${(props) => (props.isExpanded ? "8px" : "0")};
`;

const UserInfo = styled.div`
    ${flexCenter};
`;

const UserAvatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

const UserName = styled(Heading6)`
    color: ${colors.neutral[900]};
    font-size: 14px;
    font-weight: 500;
    ${flexCenter};
    margin-left: 8px;
`;

const UserStatus = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
`;

/*const DetailItem = styled.div`
    ${commonTextStyles};
    font-weight: ${(props) => props.weight || 400};
`;*/

const IconDetailItem = styled.div`
    ${flexCenter};
    ${commonTextStyles};
    font-weight: 500;
    gap: 16px;

    span {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: ${(props) => (props.selected ? colors.neutral[500] : "#5f6368")};
    }
`;

const AssignedText = styled(Body2)`
    ${commonTextStyles};
`;

const CreatedText = styled(Heading6)`
    color: ${colors.neutral[500]};
    font-size: 12px;
    font-weight: 400;
`;

const Separator = styled.hr`
    border: none;
    height: 1px;
    background-color: #eef2f3;
    margin: 0;
`;

const ActionBar = styled.div`
    ${flexCenter};
    background-color: ${colors.neutral[50]};
    justify-content: flex-end;
    gap: 24px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.2s ease-in-out;
    padding: 8px 12px;
    border-top: 1px solid ${colors.neutral[100]};
    height: 40px;
`;

const MoveDropdown = styled.div`
    position: absolute;
    background: ${colors.white};
    border: 1px solid ${colors.neutral[100]};
    border-radius: 6px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    min-width: 120px;
    z-index: 10;

    &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 16px;
        background: transparent;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        background: ${colors.white};
        border-right: 1px solid ${colors.neutral[100]};
        border-bottom: 1px solid ${colors.neutral[100]};
    }
`;

const MoveOption = styled.div`
    padding: 8px 16px;
    color: ${colors.neutral[800]};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background: ${colors.neutral[50]};
    }
`;

const MoveButtonWrapper = styled.div`
    position: relative;
    padding: 4px 0;

    &:hover ${MoveDropdown} {
        display: block;
    }

    ${MoveDropdown}:hover {
        display: block;
    }
`;

const IconDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
`;

const SelectableTaskCard = ({ task, setTaskToDelete, overdue, selectedTasks, setSelectedTasks }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { modals } = useExtensionContext();

    const {
        id,
        metin: title,
        aciklama: description,
        zaman,
        important,
        id: profileId,
        profil,
        taskColour: taskColor,
        assignedByName: assignedTo,
    } = task;

    const { ad, soyad, tel: phoneNumber, email, resim } = profil || {};
    const userName = `${ad} ${soyad}`;
    const defaultImage = toAbsoluteUrl("/media/users/default.jpg");
    const [imgSrc, setImgSrc] = useState(resim || defaultImage);

    const hasUserInfo = userName;
    const hasDetailItems = phoneNumber || email;
    const { setState } = useExtensionContext();

    const { completeTask } = useTaskComplete();
    const { refetch: fetchTaskLists } = useGetTaskLists();
    const { updateTask } = useTaskUpdate();

    const handleTaskSelection = async (taskId) => {
        try {
            if (!selectedTasks.includes(taskId)) {
                setSelectedTasks([...selectedTasks, taskId]);
                await completeTask(taskId);
                fetchTaskLists();
            }
        } catch (error) {
            // Handle error
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        handleTaskSelection(id);
    };

    const handleCardClick = (e) => {
        if (!e.target.closest(".action-bar") && !e.target.closest(".selection-circle")) {
            setIsExpanded(!isExpanded);
        }
    };

    const handleTaskDeleteClick = () => {
        setState({ selectedModal: modals.DELETE_TASK });
        setTaskToDelete(id);
    };

    const moveOptions = [
        { label: "+7 days", value: 7 },
        { label: "+3 days", value: 3 },
        { label: "+1 day", value: 1 },
        { label: "Other", value: "other" },
    ];

    const handleMove = async (days) => {
        try {
            await updateTask(id, title, zaman, profileId, days);
            fetchTaskLists();
        } catch (error) {
            //console.error("Error updating task date:", error);
        }
    };

    const actionButtons = [
        {
            variant: "passive",
            component: (
                <MoveButtonWrapper key="move-button">
                    <ButtonWithText variant="passive" buttonStyle="borderless" size="small">
                        Move
                    </ButtonWithText>
                    <MoveDropdown>
                        {moveOptions.map((option) => (
                            <MoveOption key={option.value} onClick={() => handleMove(option.value)}>
                                {option.label}
                            </MoveOption>
                        ))}
                    </MoveDropdown>
                </MoveButtonWrapper>
            ),
        },
        { variant: "danger", onClick: handleTaskDeleteClick, text: "Delete" },
        {
            variant: "primary",
            component: (
                <MoveButtonWrapper key="edit-button">
                    <ButtonWithText
                        onClick={() => {
                            setState({
                                popupContent: POPUP_CONTENTS.NEW_TASK,
                                taskPage: true,
                                selectedTask: task,
                                editMode: true,
                            });
                        }}
                        variant="primary"
                        buttonStyle="borderless"
                        size="small"
                    >
                        Edit
                    </ButtonWithText>
                </MoveButtonWrapper>
            ),
        },
    ];

    const openInNewTabProfileClick = (id) => {
        window.open(`/extension?profileId=${id}`, "_blank");
    };

    return (
        <CardContainer overdue={overdue} isExpanded={isExpanded} onClick={handleCardClick}>
            <input
                type="radio"
                name="task-selection"
                checked={selectedTasks.includes(id)}
                onChange={handleClick}
                style={{ display: "none" }}
            />
            <SelectionCircle
                className="selection-circle"
                selected={selectedTasks.includes(id)}
                onClick={handleClick}
                role="radio"
                aria-checked={selectedTasks.includes(id)}
            />
            <CardContent>
                <ContentWrapper isExpanded={isExpanded}>
                    <Title selected={selectedTasks.includes(id)}>
                        <TitleContent dangerouslySetInnerHTML={{ __html: title }} />
                        <div>
                            <ImportantIcon important={important === 1}>!</ImportantIcon>
                            <ImportantIcon important={important === 1}>!</ImportantIcon>
                        </div>
                    </Title>
                    {description && (
                        <>
                            <Description selected={selectedTasks.includes(id)}>{description}</Description>
                        </>
                    )}

                    {hasUserInfo && (
                        <>
                            <Separator />
                            <UserInfoWrapper isExpanded={isExpanded}>
                                <UserInfo>
                                    <UserAvatar src={imgSrc} alt={userName} onError={() => setImgSrc(defaultImage)} />
                                    <UserName>{userName}</UserName>
                                    <ButtonWithText
                                        iconType={"material"}
                                        variant="primary"
                                        size="small"
                                        buttonStyle="borderless"
                                        icon={"open_in_new"}
                                        onClick={() => openInNewTabProfileClick(profileId)}
                                    ></ButtonWithText>
                                </UserInfo>
                                <UserStatus style={{ backgroundColor: taskColor }} />
                            </UserInfoWrapper>
                        </>
                    )}

                    <DetailContent isExpanded={isExpanded}>
                        <Separator />

                        {/*activityName || accountName && (
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                marginBottom: "8px",
                                marginTop: "8px",
                            }}
                        >
                            {activityName && <DetailItem selected={selectedTask === id} weight={500}>{activityName}</DetailItem>}
                            {accountName && <DetailItem selected={selectedTask === id} weight={400}>{accountName}</DetailItem>}
                        </div>
                        )*/}

                        <IconDetailWrapper>
                            {phoneNumber && (
                                <IconDetailItem selected={selectedTasks.includes(id)}>
                                    <span className="material-symbols-outlined">call</span>
                                    {phoneNumber}
                                </IconDetailItem>
                            )}

                            {email && (
                                <IconDetailItem selected={selectedTasks.includes(id)}>
                                    <span className="material-symbols-outlined">mail</span>
                                    {email}
                                </IconDetailItem>
                            )}
                        </IconDetailWrapper>

                        {hasDetailItems && <Separator />}
                        {assignedTo && selectedTasks.includes(id) && (
                            <AssignedText>Assigned by {assignedTo}</AssignedText>
                        )}
                        {zaman && (
                            <CreatedText>Created on {moment(zaman).format("MMM DD, YYYY - hh:mm A")}</CreatedText>
                        )}
                    </DetailContent>
                </ContentWrapper>
            </CardContent>

            <ActionBar className="action-bar">
                {actionButtons.map((button) =>
                    button.component ? (
                        button.component
                    ) : (
                        <ButtonWithText
                            key={button.text}
                            size="small"
                            variant={button.variant}
                            buttonStyle="borderless"
                            onClick={button.onClick}
                        >
                            {button.text}
                        </ButtonWithText>
                    )
                )}
            </ActionBar>
        </CardContainer>
    );
};

export default SelectableTaskCard;
