import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import FeatherIcon from "feather-icons-react";
import { DebounceInput } from "react-debounce-input";
import { colors } from "./colors";

const statusStyles = {
    normal: css`
        border: 1px solid ${colors.passive[300]};
        color: ${colors.passive[500]};
    `,
    focus: css`
        color: ${colors.primary[900]};
        border: 2px solid ${colors.primary[900]};
        padding: 10px 14px;
    `,
    filled: css`
        color: ${colors.primary[900]};
    `,
    disabled: css`
        border-color: ${colors.neutral[300]};
        color: ${colors.neutral[300]};
        background-color: #f6f8f9;
    `,
    error: css`
        border-color: #e74c3c;
        color: #e74c3c;
    `,
};

const InputContainer = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.neutral[300]};
    border-radius: 8px;
    padding: 10px 15px;
    height: 41px;

    ${({ status }) => statusStyles[status]}
`;

const StyledDebounceInput = styled(DebounceInput)`
    border: none;
    outline: none;
    flex: 1;
    font-size: 0.91563rem;
    font-weight: 400;
    line-height: 1.2938rem;
    background-color: transparent;
    width: 100%;
    color: ${colors.neutral[800]};
    svg {
    }
    &::placeholder {
        color: ${colors.neutral[500]};
    }

    &:disabled {
        border-color: ${colors.neutral[300]};
        color: ${colors.neutral[300]};
        background-color: #f6f8f9;

        &::placeholder {
            color: ${colors.neutral[300]};
        }
    }
`;

const UnStyledDebounceInput = styled(DebounceInput)`
    border: none;
    outline: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background-color: transparent;
    width: 100%;
    &:focus {
        text-decoration: underline;
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 20px;
    height: 20px;
`;

export const Seperator = styled.div`
    width: 1px;
    height: 100%;
    margin-left: 8px;
    margin-right: 10px;
    background-color: ${({ isDisabled }) => (isDisabled ? `${colors.neutral[500]}` : `${colors.neutral[300]}`)};
`;

const IconContainerForChildren = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-width: 12px;
    min-height: 12px;
    box-sizing: content-box;
`;

const InputWithIcon = ({
    leftIcon,
    rightIcon,
    leftIconClick,
    value,
    onChange,
    placeholder,
    rightIconClick,
    disabled,
    debounceTime = 300,
    error = false,
    type,
    iconType,
    leftMaterialIcon,
    rightMaterialIcon,
    variant,
    onKeyDown,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);

    let status = "normal";

    if (disabled) {
        status = "disabled";
    } else if (error) {
        status = "error";
    } else if (isFocused) {
        status = "focus";
    } else if (value) {
        status = "filled";
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return variant !== "unstyled" ? (
        <InputContainer ref={inputRef} status={status} disabled={disabled}>
            {(leftIcon || leftMaterialIcon) && (
                <>
                    <IconContainer
                        aria-label="search-click"
                        data-testid="left-icon"
                        className="inputLeftIcon"
                        onClick={leftIconClick}
                    >
                        {iconType === "material" ? (
                            <span className="material-symbols-outlined">{leftMaterialIcon}</span>
                        ) : (
                            <FeatherIcon aria-label="search" icon={leftIcon} size={20} />
                        )}
                    </IconContainer>
                    <Seperator />
                </>
            )}
            <StyledDebounceInput
                minLength={2}
                debounceTimeout={debounceTime}
                value={value}
                type={type}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onKeyDown={onKeyDown}
            />

            {(rightIcon || rightMaterialIcon) && (
                <>
                    {typeof rightIcon === "string" || typeof rightMaterialIcon === "string" ? (
                        <IconContainer
                            aria-label="x-click"
                            data-testid="right-icon"
                            className="inputRightIcon"
                            onClick={rightIconClick}
                        >
                            {iconType === "material" && rightMaterialIcon ? (
                                <span className="material-symbols-outlined">{rightMaterialIcon}</span>
                            ) : (
                                <FeatherIcon aria-label="x" icon={rightIcon} size={20} />
                            )}
                        </IconContainer>
                    ) : (
                        <IconContainerForChildren
                            aria-label="x-click"
                            data-testid="right-icon"
                            className="inputRightIcon"
                        >
                            {rightIcon}
                        </IconContainerForChildren>
                    )}
                </>
            )}
        </InputContainer>
    ) : (
        <UnStyledDebounceInput
            minLength={2}
            debounceTimeout={debounceTime}
            value={value}
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            {...props}
        />
    );
};

export default InputWithIcon;
