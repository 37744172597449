import React from "react";
import styled from "styled-components";
import ExtensionDealSideBar from "./ExtensionDealSideBar";
import ExtensionViewDeal from "./ExtensionViewDeal";

const DealsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const DealsMenu = styled.div`
    min-width: 225px;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.colors?.neutral?.[100] || "#E5E7EB"};
`;

const DealsContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow-y: auto;
`;

const ExtensionDealsPage = ({ isActive, setIsActive }) => {
    return (
        <DealsContainer>
            <DealsMenu>
                <ExtensionDealSideBar isActive={isActive} setIsActive={setIsActive} />
            </DealsMenu>
            <DealsContent>
                <ExtensionViewDeal />
            </DealsContent>
        </DealsContainer>
    );
};

export default ExtensionDealsPage;
