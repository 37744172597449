import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useExtensionContext } from "../ExtensionContext";
import ExtensionCard from "app/popleads/components/ui-kit/ExtensionCard";
import { StyledCardContent, StyledCardFooter, StyledCardHeader } from "./common/styled";

const CoursesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 8px 12px;
`;

const ExtensionCoursesPage = () => {
    const { profile } = useExtensionContext();

    const courseList = profile?.etiket.map((etiket) => ({
        imgSrc: "/media/svg/icons/Navigation/popleads_logo.svg",
        header: <StyledCardHeader>Public | AccNA Sharm El Sheikh November 24 M2</StyledCardHeader>,
        content: <StyledCardContent>{etiket.metin}</StyledCardContent>,
        footer: <StyledCardFooter>TYPE | {moment(etiket.createdTime).format("MMMM YYYY")}</StyledCardFooter>,
    }));

    return (
        <CoursesContainer>
            {courseList.map((course) => (
                <ExtensionCard cardItems={[course]} haveViewMore={false} />
            ))}
        </CoursesContainer>
    );
};

export default ExtensionCoursesPage;
