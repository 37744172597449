import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import * as util from "../../app/functions/util";
import { isEqual, isFunction } from "lodash";
import useGetProfile from "app/popleads/hooks/useGetProfile";
import useEditProfile from "app/popleads/hooks/useEditProfile";
import useGetProfileNotes from "app/popleads/hooks/useGetProfileNotes";
import useCompanyData from "app/popleads/hooks/useCompanyData";
import { toast } from "react-toastify";
import useOlanakEkle from "app/popleads/hooks/useOlanakEkle";
import moment from "moment";
import { StyledCardContent, StyledCardFooter, StyledCardHeader } from "./components/common/styled";
import NotesFileCard from "app/popleads/components/ui-kit/NotesFileCard";
import useGetUserExtensionActivities from "app/popleads/hooks/useGetUserExtensionActivities";
import useGetTaskLists from "app/popleads/hooks/useGetTaskLists";
import ExtensionNote from "./components/ExtensionNote";

const MODALS = {
    DELETE_ATTACHMENT: "deleteAttachment",
    DELETE_TASK: "deleteTask",
};

const ExtensionContext = createContext();

export function useExtensionContext() {
    return useContext(ExtensionContext);
}

export const ExtensionConsumer = ExtensionContext.Consumer;

const initialState = {
    selectedTab: "Profile",
    popupContent: "",
    taskPage: null,
    profileId: null,
    dealId: null,
    loading: false,
    error: null,
    myActivities: [],
    selectedModal: null,
    selectedTask: null,
    editMode: false,
    selectedDate: new Date(),
};

export function ExtensionProvider({ extensionEvents, children }) {
    const [state, setState] = useState(initialState);
    const { olanakEkle } = useOlanakEkle();
    const queryProfileId = parseInt(util.getUrlQueryParam("profileId"));
    const { profile, loading, refetch } = useGetProfile(queryProfileId);
    const { companyUsers, companyGroups, companyUsersAndGroups } = useCompanyData();
    const [dealObject, setDealObject] = useState({
        campaignType: "",
        status: "",
        dealPotential: 25,
        selectedInquiry: null,
    });
    const isInhouseTypes = (campaignType) => campaignType === "In-house" || campaignType === "LVT In-house";

    const { userActivities, refetch: fetchUserExtensionActivitiesFunc } = useGetUserExtensionActivities();
    const { task: taskList, refetch: fetchTaskLists } = useGetTaskLists();

    const { profileNotes, loading: profileNotesLoading, refetch: profileNotesRefetch } = useGetProfileNotes(
        queryProfileId,
        0,
        4
    );

    const { updateProfile, loading: updateProfileLoading, error: updateProfileError } = useEditProfile();

    const setExtensionState = useCallback((nextState) => {
        setState((prevState) => {
            if (isFunction(nextState)) {
                nextState = nextState(prevState);
            }

            if (isEqual(prevState, nextState)) {
                return prevState;
            }

            return { ...prevState, ...nextState };
        });
    }, []);

    const handleUpdateProfile = async (params = {}) => {
        const result = await updateProfile({ id: profile.id, ...params });
        if (result.error) {
            return;
        }
        refetch();
        profileNotesRefetch();
    };

    const validateDealObject = (dealObj) => {
        if (!dealObj.campaignType) {
            toast.error("Campaign type is required.");
            return false;
        }
        if (isInhouseTypes(dealObj.campaignType)) {
            if (!dealObj.selectedInquiry) {
                toast.error("Inquiry is required.");
                return false;
            }

            if (!dealObj.courseName) {
                toast.error("You need to select a course name from the list.");
                return false;
            }
        }
        return true;
    };

    const createInhouseDeal = async (dealObj) => {
        const dealParams = {
            profil: dealObj.selectedInquiry.profilId,
            ad: dealObj.dealName,
            units: dealObj.numberOfUnits,
            dealCampaignType: dealObj.campaignType,
            kapanmaTarihi: dealObj.closingDate,
            accountName: dealObj.accountName,
            amount: dealObj.amount,
            durum: `${dealObj.dealPotential}%`,
            lastDurum: `${dealObj.dealPotential}%`,
            type: dealObj.type,
            paid: dealObj.paidDeal === "Yes" ? 1 : 0,
            inquiryActivityId: dealObj.selectedInquiry.activityId,
            typeChannel: dealObj.channelType,
            courseName: dealObj.courseName,
            newCourseNameForInhouse: 0,
            partnerUserId: dealObj.partnerUserId,
        };

        const result = await olanakEkle(dealParams);
        if (result.response?.status === 500) {
            if (result.response?.data?.text) {
                toast.error(result.response.data.text);
                return false;
            } else {
                toast.error("Deal could not be created.");
                return false;
            }
        }
        return true;
    };

    const handleCreateDeal = async () => {
        try {
            if (!validateDealObject(dealObject)) {
                return;
            }

            if (isInhouseTypes(dealObject.campaignType)) {
                if (!(await createInhouseDeal(dealObject))) {
                    return;
                }
            }

            toast.success("Deal created successfully.");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const attachments = [
        { fileName: "test.pdf", fileUrl: "https://www.google.com" },
        { fileName: "deneme.png", fileUrl: "https://www.google.com" },
    ];

    useEffect(() => {
        const noteList = profileNotes?.map((note) => {
            return {
                id: note.id,
                icon: note.tip === "otomatik" && (
                    <StyledCardContent
                        variant={"warning"}
                        className="material-symbols-outlined"
                        style={{ fontSize: "22px", fontWeight: "300" }}
                    >
                        sync_problem
                    </StyledCardContent>
                ),
                header: <StyledCardHeader>{moment(note.createdTime).format("MMM DD, YYYY HH:mm")}</StyledCardHeader>,
                content: <ExtensionNote note={note} />,
                footer: (
                    <div>
                        <StyledCardFooter>Created by {note.kayitci}</StyledCardFooter>
                        <NotesFileCard attachments={attachments} />
                    </div>
                ),
            };
        });

        setState({ ...state, noteList: noteList });
    }, [profileNotes]);

    const addProfileNote = (note) => {
        const noteList = [
            {
                id: note.id,
                header: <StyledCardHeader>{moment(note.createdTime).format("MMM DD, YYYY HH:mm")}</StyledCardHeader>,
                content: <ExtensionNote note={note} />,
                footer: <StyledCardFooter>Created by {note.kayitci}</StyledCardFooter>,
            },
            ...state.noteList,
        ];
        setState({ ...state, noteList });
    };

    const value = {
        ...state,
        profile,
        isProfileLoading: loading,
        setState: setExtensionState,
        refetchProfile: refetch,
        handleUpdateProfile,
        updateProfileLoading,
        updateProfileError,
        profileNotes,
        profileNotesLoading,
        profileNotesRefetch,
        addProfileNote,
        companyUsers,
        companyGroups,
        companyUsersAndGroups,
        dealObject,
        setDealObject,
        handleCreateDeal,
        userActivities,
        fetchUserExtensionActivitiesFunc,
        taskList,
        fetchTaskLists,
        // Extension olayları
        onTabChange: extensionEvents?.onTabChange,
        onPopupOpen: extensionEvents?.onPopupOpen,
        onPopupClose: extensionEvents?.onPopupClose,
        onProfileLoad: extensionEvents?.onProfileLoad,
        onDealLoad: extensionEvents?.onDealLoad,
        onError: extensionEvents?.onError,
        modals: MODALS,
    };

    return <ExtensionContext.Provider value={value}>{children}</ExtensionContext.Provider>;
}
