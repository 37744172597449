import React from "react";
import ExtensionCard, { ExtensionCardHeader } from "app/popleads/components/ui-kit/ExtensionCard";
import ButtonWithText from "app/popleads/components/ui-kit/Button";
import { POPUP_CONTENTS } from "../utils/constants";
import { useExtensionContext } from "../ExtensionContext";
import useTaskUpdate from "app/popleads/hooks/useTaskUpdate";
import useGetTaskLists from "app/popleads/hooks/useGetTaskLists";
import {
    ImportantTaskIcon,
    StyledCardFooter,
    StyledTaskListContent,
    StyledTaskListHeader,
    StyledTaskListItemContent,
    StyledTaskListItemType,
    TaskColorIcon,
} from "./common/styled";
import moment from "moment";
import styled from "styled-components";
import { colors } from "app/popleads/components/ui-kit/colors";

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.neutral[100]};
    justify-content: flex-end;
    gap: 24px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3px;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    padding: 8px 12px;
    height: 40px;
    z-index: 1;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;

const CardWrapper = styled.div`
    position: relative;
    margin-bottom: 8px;
    transition: margin-bottom 0.2s ease-in-out;

    &:hover {
        margin-bottom: 48px;
        .action-bar {
            visibility: visible;
            opacity: 1;
        }
    }
`;
const StyledCardContainer = styled.div`
    div {
        &:hover {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
`;
const MoveDropdown = styled.div`
    position: absolute;
    background: ${colors.white};
    border: 1px solid ${colors.neutral[100]};
    border-radius: 6px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    min-width: 120px;
    z-index: 10;

    &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 16px;
        background: transparent;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        background: ${colors.white};
        border-right: 1px solid ${colors.neutral[100]};
        border-bottom: 1px solid ${colors.neutral[100]};
    }
`;

const MoveOption = styled.div`
    padding: 8px 16px;
    color: ${colors.neutral[800]};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background: ${colors.neutral[50]};
    }
`;

const MoveButtonWrapper = styled.div`
    position: relative;
    padding: 4px 0;

    &:hover ${MoveDropdown} {
        display: block;
    }

    ${MoveDropdown}:hover {
        display: block;
    }
`;

const ExtensionTaskCard = ({ setTaskToDelete }) => {
    const { modals, setState, profile } = useExtensionContext();
    const { updateTask } = useTaskUpdate();
    const { refetch: fetchTaskLists } = useGetTaskLists();

    const handleTaskDeleteClick = (taskId) => {
        setState({ selectedModal: modals.DELETE_TASK });
        setTaskToDelete(taskId);
    };

    const handleTaskClick = (zaman) => {
        setState({
            selectedTab: "Tasks",
            selectedDate: new Date(zaman),
        });
    };

    const moveOptions = [
        { label: "+7 days", value: 7 },
        { label: "+3 days", value: 3 },
        { label: "+1 day", value: 1 },
        { label: "Other", value: "other" },
    ];

    const handleMove = async (taskId, title, zaman, profileId, days) => {
        try {
            await updateTask(taskId, title, zaman, profileId, days);
            fetchTaskLists();
        } catch (error) {}
    };

    const taskList = profile?.gorev?.map((gorev) => {
        const task = {
            header: (
                <StyledTaskListHeader>
                    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <TaskColorIcon color={gorev.taskColour} />
                        {moment(gorev.zaman).format("MMM DD, YYYY - hh:mm A")}
                    </div>
                    {gorev.important === 1 && <ImportantTaskIcon>!</ImportantTaskIcon>}
                </StyledTaskListHeader>
            ),
            content: (
                <StyledTaskListContent>
                    <StyledTaskListItemType>{gorev.type}</StyledTaskListItemType>
                    <StyledTaskListItemContent dangerouslySetInnerHTML={{ __html: gorev.metin }} />
                </StyledTaskListContent>
            ),
            footer: (
                <StyledCardFooter>
                    Created on {moment(gorev.createdTime).format("MMM DD, YYYY - hh:mm A")}
                </StyledCardFooter>
            ),
        };

        const actionButtons = [
            {
                variant: "passive",
                component: (
                    <MoveButtonWrapper key="move-button">
                        <ButtonWithText variant="passive" buttonStyle="borderless" size="small">
                            Move
                        </ButtonWithText>
                        <MoveDropdown>
                            {moveOptions.map((option) => (
                                <MoveOption
                                    key={option.value}
                                    onClick={() =>
                                        handleMove(gorev.id, gorev.metin, gorev.zaman, gorev.profileId, option.value)
                                    }
                                >
                                    {option.label}
                                </MoveOption>
                            ))}
                        </MoveDropdown>
                    </MoveButtonWrapper>
                ),
            },
            { variant: "danger", onClick: () => handleTaskDeleteClick(gorev.id), text: "Delete" },
            {
                variant: "primary",
                onClick: () => {
                    setState({
                        popupContent: POPUP_CONTENTS.NEW_TASK,
                        taskPage: true,
                        selectedTask: gorev,
                        editMode: true,
                    });
                },
                text: "Edit",
            },
        ];

        return (
            <CardWrapper key={gorev.id}>
                <StyledCardContainer onClick={() => handleTaskClick(gorev.zaman)} style={{ cursor: "pointer" }}>
                    <ExtensionCard cardItems={[task]} viewMore={false} />
                </StyledCardContainer>
                <ActionBar className="action-bar">
                    {actionButtons.map((button) =>
                        button.component ? (
                            button.component
                        ) : (
                            <ButtonWithText
                                key={button.text}
                                size="small"
                                variant={button.variant}
                                buttonStyle="borderless"
                                onClick={button.onClick}
                            >
                                {button.text}
                            </ButtonWithText>
                        )
                    )}
                </ActionBar>
            </CardWrapper>
        );
    });

    return (
        <>
            <ExtensionCardHeader
                cardTitle={"Tasks"}
                button={
                    <ButtonWithText
                        icon="add"
                        iconType="material"
                        variant="primary"
                        buttonStyle="default"
                        size="circle"
                        onClick={() => {
                            setState({ popupContent: POPUP_CONTENTS.NEW_TASK });
                        }}
                    />
                }
            />

            {taskList}
        </>
    );
};

export default ExtensionTaskCard;
