import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Select, { components } from "react-select";
import { withAsyncPaginate } from "react-select-async-paginate";
import AsyncSelect from "react-select/async";
import { colors } from "./colors";
import styled from "styled-components";
import ButtonWithText from "./Button";
import CreatableSelect from "react-select/creatable";

const StyledDownIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.neutral[900]};
    margin-right: 4px;
`;

const StyledCancelIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.primary[900]};
    margin-right: 8px;
`;

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.neutral[500]};
    border-radius: 4px;
    opacity: 1;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:checked {
        background-color: ${colors.primary[900]};
        border-color: ${colors.primary[900]};
    }

    &:checked::after {
        content: "✔";
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        border-color: ${colors.primary[700]};
    }

    position: relative;
`;

const StyledTagContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const StyledHighlightLetter = styled.b`
    color: ${colors.primary[900]};
`;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? `2px solid ${colors.primary[900]}` : `1px solid ${colors.neutral[300]}`,
        borderRadius: "8px",
        boxSizing: "border-box",
        borderColor: "none",
        boxShadow: "none",
        padding: state.isFocused ? "0 7px" : "0 8px",
        height: "40px",
        fontSize: "0.91563rem",
        fontWeight: 400,
        lineHeight: "1.2938rem",
        "&:hover": {
            borderColor: "none",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: colors.neutral[500],
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: "8px",
    }),
    option: (provided) => ({
        ...provided,
        color: colors.neutral[900],
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#F6F8F9", //TODO: tekin-renk eşleştirmesini yapamadık eklenmesi gerekiyor
            color: colors.neutral[900],
        },
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0 5px",
    }),

    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
};

const getHighlightLetter = (label, userInput) => {
    const regex = new RegExp(`(${userInput})`, "gi");

    return label
        .split(regex)
        .map((part, index) =>
            regex.test(part) ? <StyledHighlightLetter key={index}>{part}</StyledHighlightLetter> : part
        );
};

const Option = (props) => {
    const userInput = props?.selectProps?.inputValue || "";
    const label = props?.data?.label || "";

    const isMatch = label.toLowerCase().includes(userInput.toLowerCase());

    return isMatch ? (
        <div>
            <components.Option {...props}>
                <div className="d-flex justify-content-between align-items-center">
                    <div>{userInput?.length ? getHighlightLetter(label, userInput) : label}</div>
                    {props.isMulti && <StyledCheckbox checked={props.isSelected} onChange={() => null} />}
                </div>
            </components.Option>
        </div>
    ) : null;
};

const ValueContainer = ({ children, ...props }) => {
    const { getValue, selectProps } = props;
    const nbValues = getValue().length;

    if (!selectProps.menuIsOpen) {
        return (
            <components.ValueContainer {...props}>
                {nbValues > 0 ? selectProps.placeholder : children}
            </components.ValueContainer>
        );
    }

    return (
        <components.ValueContainer {...props}>
            {nbValues > 0 ? `${nbValues} items selected` : children}
        </components.ValueContainer>
    );
};

const CreatableAsyncPaginateComp = withAsyncPaginate(CreatableSelect);

const CustomSelect = forwardRef(({ classNamePrefix = "customSelectInput", value, ...props }, ref) => {
    const selectRef = useRef(null);
    const containerRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            selectRef,
        }),
        [selectRef]
    );

    const handleChange = (value, actionMeta) => {
        if (actionMeta?.action === "create-option") {
            props.onChange(value);
        } else {
            props.onChange(value);
        }
    };

    const renderDropdownIndicator = () => (
        <StyledDownIconWrapper aria-label="chevron-down" role="img">
            <span className="material-symbols-outlined">keyboard_arrow_down</span>
        </StyledDownIconWrapper>
    );

    const renderClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => (
        <StyledCancelIconWrapper {...restInnerProps} ref={ref} aria-label="x" role="img">
            <span className="material-symbols-outlined">cancel</span>
        </StyledCancelIconWrapper>
    );

    const commonComponents = {
        DropdownIndicator: renderDropdownIndicator,
        ClearIndicator: renderClearIndicator,
        Option,
        ...(props.isMulti && { ValueContainer }),
    };

    const renderSelect = () => {
        const commonProps = {
            ref: selectRef,
            styles: customStyles,
            onChange: handleChange,
            value: value,
            placeholder: props.placeholder,
            options: props.options,
            closeMenuOnSelect: !props.isMulti,
            hideSelectedOptions: !props.isMulti,
            components: commonComponents,
            menuPortalTarget: document.getElementById("newFilterMenu"),
            menuPosition: "fixed",
            cacheOptions: props.cacheOptions,
            defaultOptions: props.defaultOptions,
            ...props,
        };

        if (!props.loadOptions) {
            return props.isCreatable ? <CreatableSelect {...commonProps} /> : <Select {...commonProps} />;
        }

        return props.isCreatable ? (
            <CreatableAsyncPaginateComp
                {...commonProps}
                loadOptions={(inputValue, callback) =>
                    props.loadOptions(inputValue, callback, 1, props.fn, props.setState)
                }
                // defaultOptions={props.state}
            />
        ) : (
            <AsyncSelect
                {...commonProps}
                loadOptions={(inputValue, callback) =>
                    props.loadOptions(inputValue, callback, 1, props.fn, props.setState)
                }
                // defaultOptions={props.state}
            />
        );
    };
    //todo: dropdown açıkken scroll yapınca dropdown yukarı/aşağı iniyor.
    //todo: optionlar silmeye çalışırken hata veriyor. istek atıyor?
    //todo: seçilen taglerin stilleri düzeltilmeli
    //todo: normal select ile multi select arasında yükseklik farkları var
    return (
        <div ref={containerRef}>
            {renderSelect()}
            {props.isMulti && value?.length > 0 && (
                <StyledTagContainer>
                    {value?.map((item, index) => (
                        <ButtonWithText
                            key={index}
                            variant="neutral"
                            type="tag"
                            size="option"
                            rightIcon="x"
                            onClick={(e) => {
                                e.preventDefault();
                                handleChange(value.filter((buttonItem) => buttonItem.value !== item.value));
                            }}
                        >
                            {item?.label}
                        </ButtonWithText>
                    ))}
                </StyledTagContainer>
            )}
        </div>
    );
});

export default CustomSelect;
